import { defineReceiver, defineCommand } from "@drapejs/invoker";
import { request, query } from "@distancify/drapejs-litium";
import { gql } from "graphql-request";

export const commands = {
  fetchProduct: defineCommand<{
    url: string;
    baseProductId: string;
    variantId?: string;
  }>("fetchProduct"),
  fetchVariant: defineCommand<{
    url: string;
    variantId: string;
  }>("fetchVariant"),
};

export const receivers = {
  fetchProduct: defineReceiver(
    commands.fetchProduct,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          query fetchProduct($url: String!, $baseProductId: String!, $variantId: String) {
            session(url: $url) {
              baseProduct(id: $baseProductId) {
                id
                fieldsJson
                purchaseQuantityMultiplier
                variants {
                  id
                  fields
                  technicalSpecifications {
                    id
                    name
                    value
                  }
                }
                selectedVariant(variantId: $variantId) {
                  inactive
                  variantLabel
                  baseProductUrl
                  id
                  articleNumber
                  variantName
                  systemId
                  purchaseQuantityFactor
                  fields
                  images {
                    id
                    filename
                  }
                  showCustomerPrices
                  prices {
                    originalPrice
                    campaignPrice
                  }
                  customerPrices {
                    originalPrice
                    campaignPrice
                  }
                  stock {
                    totalStockAvailable
                    currentStock {
                      amount
                      date
                    }
                    incomingStock {
                      date
                      amount
                    }
                    expectedShippingDateTime
                    deliveryDateFromSupplier
                  }
                }
              }
            }
          }
        `),
        {
          url: command.url,
          baseProductId: command.baseProductId,
          variantId: command.variantId || ''
        }
      );

      return result.session.baseProduct;
    }
  ),
  fetchVariant: defineReceiver(
    commands.fetchVariant,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          query fetchVariant($url: String!, $variantId: String!) {
            session(url: $url) {
              variant(id: $variantId) {
                id
                brandName
                variantName
                url
                images {
                  id
                  filename
                }
              }
            }
          }
        `),
        {
          url: command.url,
          variantId: command.variantId,
        }
      );

      return result.session.variant;
    },
    "litium"
  ),
};
