import { inject, ref, InjectionKey, Ref, provide, readonly } from "vue";
import { invokeKey, fetchPageBuilder } from "@drapejs/core";
import * as reviews from "@/connectors/reviews";
import * as tycka from "@/connectors/tycka";

import useUser from "@/composables/useUser";
import useContext from "@/composables/useContext";
import useWebsiteTexts from "@/composables/useWebsiteTexts";

export const reviewOrderDataKey: InjectionKey<Ref<any>> =
  Symbol("reviewOrderData");

export const getReviewsStatusKey: InjectionKey<({ orderId }: any) => void> =
  Symbol("getReviewsStatus");

export const addReviewKey: InjectionKey<({ variantId, review }: any) => void> =
  Symbol("addReview");

export const editReviewKey: InjectionKey<
  ({ reviewId, review }: any) => void
> = Symbol("editReview");

export default function () {
  const { user } = useUser();
  const { route } = useContext();
  const { websiteText } = useWebsiteTexts();

  const invoke = inject(invokeKey);
  const reviewStatusFetched = ref(false);
  const reviewStatus = ref();

  provide(reviewOrderDataKey, readonly(reviewStatus));
  provide(getReviewsStatusKey, getReviewsStatus);
  provide(addReviewKey, addReview);
  provide(editReviewKey, editReview);

  async function getReviewsStatus({ reviewerId, orderId }: any) {
    try {
      if (!reviewerId || !orderId) {
        console.warn(
          `Can't get the review status for order ${orderId}. Order id missing`
        );
        return;
      }
      
      const request: any = fetchPageBuilder(
        route.protocol,
        route.host,
        route.pathname,
        {
          ...route.query,
        },
        ""
      );

      request.reviewerId = reviewerId;
      request.orderId = orderId;

      const response = await invoke?.(
        reviews.commands.getReviewsStatus,
        request
      );

      if (response) {
        reviewStatus.value = response;
      }

      return response;
    } catch (err) {
      console.error(err);
    } finally {
      reviewStatusFetched.value = true;
    }
  }

  function setReviewStatus(data: any) {
    reviewStatus.value = data;
    reviewStatusFetched.value = true;
  }

  async function addReview({ variantId, review }: any) {
    if (
      !reviewStatus.value?.authorId ||
      !reviewStatus.value?.id ||
      !variantId ||
      !review
    ) {
      throw websiteText("reviews__missing_data_or_unauthorized").value;
    }
    const request: any = {};

    request.transactionId = reviewStatus.value.id;
    request.authorId = reviewStatus.value.authorId;
    request.transactionSecret = reviewStatus.value.secret;

    request.variantId = variantId;
    request.review = review;

    const response = await invoke?.(tycka.commands.addReview, request);
    return response;
  }

  async function editReview({ reviewId, review }: any) {

    const request = {
      reviewId,
      review,
      ...fetchPageBuilder(
        route.protocol,
        route.host,
        route.pathname,
        {
          ...route.query,
        },
        ""
      ),
    };
    const response = await invoke?.(reviews.commands.updateReview, request);

    if (response.message !== "ok") {
      throw websiteText("global__generic_error").value;
    }
    
    return response;
  }

  async function getReviewsByAuthor({ authorId, take }: any) {
    try {
      const command =
        user.value?.isAuthenticated && user.value?.person?.id === authorId
          ? reviews.commands.privateReviewsByAuthor
          : tycka.commands.reviewsByAuthor;

      let request = {
        authorId,
        take,
      };

      if (command === reviews.commands.privateReviewsByAuthor) {
        request = {
          ...request,
          ...fetchPageBuilder(
            route.protocol,
            route.host,
            route.pathname,
            {
              ...route.query,
            },
            ""
          ),
        };
      }

      const response = await invoke?.(command, request);

      return response;
    } catch (err) {
      console.error(err);
    }
  }

  async function getReviewsByProduct({ baseProductId, take }: any) {
    try {
      const response = await invoke?.(tycka.commands.reviewsByProduct, {
        baseProductId,
        take,
      });

      return response;
    } catch (err) {
      console.error(err);
    }
  }

  return {
    getReviewsStatus,
    setReviewStatus,
    reviewStatus,
    addReview,
    reviewStatusFetched,
    getReviewsByAuthor,
    getReviewsByProduct,
  };
}

export function getReviewContext() {
  return {
    reviewStatus: inject(reviewOrderDataKey, ref<any>(<any>{})),
    getReviewsStatus: inject(getReviewsStatusKey, ({ orderId }: any) => null),
    addReview: inject(addReviewKey, ({ variantId, review }: any) => ""),
    editReview: inject(
      editReviewKey,
      ({ reviewId, review }: any) => ""
    ),
  };
}
