<template><div class="skeleton"></div></template>

<style>
.skeleton {
  width: 100%;
  height: 20px;
  background-color: #F6F6F6;
  border-radius: 2px;
  animation: loadingAnimation 1.5s infinite ease-in-out;
}

@keyframes loadingAnimation {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}
</style>
