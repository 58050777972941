<script>
export default {
  emits: ['back'],
  methods: {
    goBack() {
      this.$emit('back');
    },
  },
};
</script>

<template>
  <div class="my-pages-back">
    <span class="my-pages-back__back" @click="goBack()">
      <img
        class="my-pages-back__icon"
        src="../../../static/icons/arrow_left_icon.svg"
      />

      {{ $globalTexts.global__label_back || 'global__label_back' }}
    </span>
  </div>
</template>

<style>
.my-pages-back {
  display: flex;
  align-items: center;
  padding: 11px 14px;
  line-height: 22px;
  background-color: var(--color-neutral-00);
  box-shadow: 0px 3px 15px rgba(9, 30, 66, 0.08);
}

.my-pages-back__back {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.my-pages-back__icon {
  height: 16px;
  padding-right: 1rem;
}

.my-pages-back__exit {
  margin-left: auto;
  height: 16px;
  cursor: pointer;
}

@media (--phone) {
  .my-pages-back {
    padding: 21px 30px;
  }
}
</style>
