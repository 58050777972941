/* eslint-disable linebreak-style */
<script>
import { computed } from 'vue';
import { fetchPageBuilder } from '@drapejs/core';

import { useCartFunctions } from '../helpers/cart-actions';

import useStock from '../composables/useStock';

import ImageWithFallback from '@/components/ImageWithFallback.vue';
import QuantityInput from './QuantityInput.vue';
import DisplayPrice from '@/components/DisplayPrice.vue';

export default {
  setup(props) {
    const { getAllStockItems } = useStock();

    const allStockItems = computed(() => {
      return getAllStockItems({
        variant: props.row,
        quantity: props.row.quantity
      });
    })

    return {
      ...useCartFunctions(),
      allStockItems,
    };
  },
  components: {
    QuantityInput,
    ImageWithFallback,
    DisplayPrice
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    inputQuantity: '',
    isQuantityExpanded: false,
    isMounted: false,
    error: '',
    message: '',
  }),
  computed: {
    productName() {
      return this.row.variantName || '';
    },
    articleNumber() {
      return this.row.articleNumber || '';
    },
    unitPrice() {
      const price = this.row.unitPrice < this.row.unitListPrice
       ? this.row.unitPrice 
       : this.row.unitListPrice;
      return price || 0;
    },
    originalPrice() {
      return this.row.originalPrice || 0;
    },
    currentPriceTotal() {
      return (((this.unitPrice * 10 ** this.currencyDecimals) / 10 ** this.currencyDecimals) * this.quantity);
    },
    originalPriceTotal() {
      return (((this.originalPrice * 10 ** this.currencyDecimals) / 10 ** this.currencyDecimals) * this.quantity);
    },
    currencyDecimals() {
      return this.$cart.currencyDecimals || 0;
    },
    quantity() {
      return this.row.quantity;
    },
    unitOfMeasurement() {
      return this.row?.unitOfMeasurement || '';
    },
    packQuantity() {
      return this.row?.packaging?.packQuantity || 1;
    },
    packagingInfo() {
      return this.row?.packaging?.caption || '';
    },
    quantityMultiplier() {
      return this.row?.quantityMultiplier || 1;
    },
  },
  methods: {
    getProductImageUrl(row) {
      return this.$toLitiumMediaUrl(row?.imageId, { maxHeight: 300 }) || '';
    },
    async onRemove() {
      await this.change({ rowId: this.row.id, quantity: 0 });
    },
    async onChange(quantity) {

      var remainder = quantity % this.quantityMultiplier;
      if(remainder != 0) {        
        
        quantity -= remainder;
        this.$refs.quantityInput.setAmount(quantity);

        return;
      }

      await this.change({ rowId: this.row.id, quantity });
    },
    async change(row) {
      const result = await this.setQuantityOnRow(row.rowId, row.quantity);

      const { error } = result;
      if (error === 'NOT_ENOUGH_STOCK') {
        this.error = this.$replaceTokens(
          this.$globalTexts.cart__available_stock,
          {
            amount: this.quantity,
          }
        );
      } else {
        this.error = '';
      }

      if (this.$refs.quantityInput) {
        this.$refs.quantityInput.setAmount(this.quantity);
      }
    },
    buildRequest() {
      return fetchPageBuilder(
        this.$route.protocol,
        this.$route.host,
        this.$route.pathname,
        {
          ...this.$route.query,
        },
        ''
      );
    },
  },
  watch: {
    isQuantityExpanded() {
      if (!this.isQuantityExpanded) {
        this.error = '';
      } else {
        this.$nextTick(() => this.$refs.quantityInput.setMessage(this.message));
      }
    },
  },
  mounted() {
    this.isMounted = true;

    if (this.quantityMultiplier !== 1) {
      this.message = this.$replaceTokens(this.$globalTexts.product__quantity_factor || 'product__quantity_factor', {
        amount: this.quantityMultiplier
      });
    }
  },
};
</script>

<template>
  <div class="cart-item">
    <div class="cart-item__info">
      <router-link :to="row.url" class="cart-item__thumbnail-container">
        <div class="cart-item__variant-image-overlay"></div>
        <ImageWithFallback
          class="cart-item__variant-image"
          v-if="isMounted"
          :image="getProductImageUrl(row)"
          :alt="productName"
          loading="lazy"
        />
      </router-link>

      <div class="cart-item__details">
        <div>
          <div class="cart-item__product-name">
            {{ productName }}
          </div>
          <div class="cart-item__article-number">
            {{ articleNumber }}
          </div>
        </div>
      </div>
      <div class="cart-item__toolbar">
        <img
          class="cart-item__remove"
          src="../static/icons/remove_cart_row_icon.svg"
          @click="onRemove()"
        />
        <div class="cart-item__pricing-item">
          <DisplayPrice
            :original-price="originalPriceTotal"
            :campaign-price="currentPriceTotal"
            :vertical-stack="true" />
        </div>
      </div>
    </div>
    <div
      class="cart-item__quantity-container"
      :class="{ 'cart-item__quantity--expanded': isQuantityExpanded }"
    >
      <div class="cart-item__quantity">
        <div
          class="cart-item__quantity-toggle"
          @click="isQuantityExpanded = !isQuantityExpanded"
        >
          <div class="cart-item__quantity-caption">
            {{ $globalTexts.global__quantity || 'global__quantity' }}:
            <span class="cart-item__quantity-amount">{{ quantity }}</span>
          </div>
          <div class="cart-item__quantity-amount-toggle">
            <img
              :class="{ 'cart-item__chevron--up': isQuantityExpanded }"
              src="../static/icons/chevron_down_icon.svg"
            />
          </div>
        </div>
        <quantity-input
          :quantity="quantity"
          :pack-quantity="packQuantity"
          :packaging-info="packagingInfo"
          :stock-items="allStockItems"
          :unit-of-measurement="unitOfMeasurement"
          :error="error"
          @update:quantity="onChange"
          :disabled="requestPending"
          v-if="isQuantityExpanded"
          ref="quantityInput"
        />
      </div>
    </div>
  </div>
</template>

<style>
.cart-item {
  padding: 0.75rem 0.5rem 0;
  border-radius: 3px;
  border: 1px solid var(--color-neutral-10);
  background-color: var(--color-text-white);
  margin-bottom: 1.25rem;
}

.cart-item__remove {
  cursor: pointer;
}

.cart-item__chevron--up {
  transform: rotate(180deg);
}

.cart-item__quantity-container {
  margin: 0 -0.5rem -0.5rem;
}

.cart-item__quantity--expanded {
  margin: 0 -0.5rem 0;
  box-shadow: inset 3px 3px 8px rgba(0, 81, 121, 0.08);
  background-color: rgba(237, 242, 244, 0.3);
}

.cart-item__quantity {
  border-top: 1px solid var(--color-neutral-10);
  padding: 0 0.5rem 0.5rem;
}

.cart-item__quantity .quantity-input {
  margin-bottom: 0.25rem;
}

.cart-item__quantity .quantity-input_error {
  padding-top: 0.25rem;
}

.cart-item__quantity-toggle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.45rem 0;
  cursor: pointer;
}

.cart-item__quantity-amount-toggle {
  display: flex;
  align-items: center;
  user-select: none;
}

.cart-item__quantity-caption,
.cart-item__quantity-amount {
  font-size: var(--size-xs);
  text-transform: uppercase;
  line-height: 25px;
  color: var(--color-main-90);
}

.cart-item__quantity-amount {
  font-weight: 700;
}

.cart-item .display-price__original-price-wrap {
  justify-content: flex-end;
}

.cart-item .display-price__campaign-price-wrap,
.cart-item .display-price__original-price-wrap {
  gap: 5px;
  font-size: var(--size-m);
  font-weight: 700;
}

.cart-item .display-price__original-price-wrap--with-campaign {
  gap: 3px;
  padding-top: 3px;
}

.cart-item .display-price__original-price-wrap--with-campaign .display-price__original-price {
  font-weight: 400;
}

.cart-item .display-price__campaign-price {
  margin-right: 0;
}

.cart-item .display-price__original-price--disabled {
  font-size: var(--size-xxs);
}

.cart-item .display-price__vat-setting {
  font-size: var(--size-xxs);
}

.cart-item__subtotal {
  font-weight: 700;
  color: var(--color-main-90);
}

.cart-item__toolbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.cart-item__info {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0 0 1rem;
  position: relative;
}

.cart-item__thumbnail-container {
  position: relative;
  width: 93px;
  height: 120px;
  user-select: none;
}

.cart-item__thumbnail {
  padding-top: 130%;
}

.cart-item__image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: var(--color-neutral-07);
  mix-blend-mode: darken;
}

.cart-item__variant-image {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  margin: auto;
}

.cart-item__variant-image-overlay {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: var(--color-neutral-07);
  mix-blend-mode: darken;
}

.cart-item__details {
  width: 100%;
  padding: 0 0 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.cart-item__product-name {
  color: var(--color-main-90);
  font-weight: 700;
  display: inline-block;
  font-size: var(--size-xxs);
  letter-spacing: 0.36px;
  line-height: 14px;
  vertical-align: top;
}

.cart-item__article-number {
  font-size: var(--size-xxs);
  letter-spacing: 0.36px;
  line-height: 14px;
  color: var(--color-neutral-60);
}

.cart-item__product-price-original {
  color: var(--color-gray-1200);
  position: relative;
}

.cart-item__product-price-original::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -1px;
  right: -1px;
  border-top: 2px solid var(--color-gray-1200);
}

.cart-item__article-number,
.cart-item__product-price,
.cart-item__color-size {
  margin: 0.15rem 0;
  font-size: var(--size-xxs);
}

.cart-item__color-size {
  margin-bottom: 0.5rem;
}

.cart-item__quantity-value,
.cart-item__quantity-decrease,
.cart-item__quantity-increase {
  color: var(--color-gray-1100);
  flex: 1 1 0px;
  padding: 0.5rem;
}

.cart-item__quantity-decrease,
.cart-item__quantity-increase {
  cursor: pointer;
}

.cart-item__quantity-decrease:hover,
.cart-item__quantity-increase:hover {
  color: var(--color-gray-900);
}

.cart-item__delete {
  font-size: var(--size-xs);
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: var(--color-gray-700);
  align-self: flex-end;
  border-bottom: 1px solid var(--color-gray-700);
  user-select: none;
}

.cart-item__delete:hover,
.cart-item__delete:focus {
  color: var(--color-gray-900);
  border-color: var(--color-gray-900);
}
</style>
