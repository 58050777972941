/* eslint-disable @typescript-eslint/camelcase */
import { ref } from 'vue';
import { sales } from '@distancify/drapejs-litium';
import { useGA4 } from '@distancify-storefront/tracking-gtm';

import useContext from '@/composables/useContext';
import useExecuteCommand from '@/composables/useExecuteCommand';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import useStock from '@/composables/useStock';
import { ERRORS } from '@/composables/constants';

export function useCartFunctions() {
  const { route, cart, navigateToLoginPage } = useContext();
  const { executeCommand } = useExecuteCommand();
  const { websiteText } = useWebsiteTexts();
  const { getAllStockItems } = useStock();

  const requestPending = ref(false);

  const ga4 = useGA4({
    useLegacyEnhancedECommerce: true,
  });

  function handleAnalytics(beforeRow: any, afterRow: any) {
    const rowWasDeleted = beforeRow?.id && !afterRow?.id;

    const quantityWasDecreased =
      beforeRow?.id && afterRow?.id && beforeRow.quantity > afterRow.quantity;

    const quantityWasIncreased =
      (beforeRow?.id && afterRow?.id && beforeRow.quantity < afterRow.quantity) ||
      (!beforeRow?.id && afterRow?.id);

    if (rowWasDeleted || quantityWasDecreased) {
      const quantityRemoved = rowWasDeleted
        ? beforeRow.quantity
        : beforeRow.quantity - afterRow.quantity;
      ga4.removeFromCart(cart.value?.currency?.id, [
        {
          item_id: beforeRow.baseProductId,
          item_variant: beforeRow.articleNumber,
          item_name: beforeRow.googleAnalyticsName || beforeRow.variantName,
          price: beforeRow.unitListPrice,
          discount: beforeRow.unitListPrice - beforeRow.unitPrice,
          quantity: quantityRemoved,
        },
      ]);
    }

    if (quantityWasIncreased) {
      const quantityAdded = afterRow.quantity - (beforeRow?.quantity || 0);
      ga4.addToCart(cart.value?.currency?.id, [
        {
          item_id: afterRow.baseProductId,
          item_variant: afterRow.articleNumber,
          item_name: afterRow.googleAnalyticsName || afterRow.variantName,
          price: afterRow.unitListPrice,
          discount: afterRow.unitListPrice - afterRow.unitPrice,
          quantity: quantityAdded,
        },
      ]);
    }
  }

  return {
    async addToCart(variant: any, quantity: number) {
      try {
        
        if(!variant?.articleNumber) {
          return;
        }

        requestPending.value = true;
        const beforeRow = {
          ...cart.value?.rows?.find(
            (r: { articleNumber: string }) =>
              r.articleNumber == variant.articleNumber
          ),
        };

        const request = <any>{};

        request.articleNumber = variant.articleNumber;
        request.quantity = quantity;

        const result = await executeCommand(sales.addToCart, request);

        if (result.error !== 'NONE') {
          if (result.error == ERRORS.NOT_AUTHENTICATED) {
            const search = route.value?.query || {};
            const query = {
              ...search,
              action: 'add',
            };
            navigateToLoginPage(query);
            return;
          } else if (result.error === 'NOT_ENOUGH_STOCK') {
            const allStockItems = getAllStockItems({
              variant: variant,
              quantity: quantity,
            });
            const amount = allStockItems[allStockItems.length - 1].amount;
            throw (
              websiteText('cart__available_stock', {
                amount,
              }).value || 'cart__available_stock'
            );
          } else {
            throw websiteText('cart__add_to_cart_error').value;
          }
        }

        const afterRow = {
          ...result.cart.rows?.find(
            (r: { articleNumber: string }) =>
              r.articleNumber == variant.articleNumber
          ),
        };

        handleAnalytics(beforeRow, afterRow);

        return result;
      } finally {
        requestPending.value = false;
      }
    },
    async setQuantityOnRow(rowId: string, quantity: number) {
      try {
        requestPending.value = true;

        const beforeRow = {
          ...cart.value?.rows?.find((r: { id: string }) => r.id == rowId),
        };

        const request = <any>{};

        request.rowId = rowId;
        request.quantity = quantity;

        const result = await executeCommand(sales.setRowQuantity, request);

        if (result.error !== 'NONE') {
          if (result.error == ERRORS.NOT_AUTHENTICATED) {
            navigateToLoginPage();
            return;
          } else if (result.error === 'NOT_ENOUGH_STOCK') {
            const allStockItems = getAllStockItems({
              variant: beforeRow,
              quantity: quantity,
            });
            const amount = allStockItems[allStockItems.length - 1].amount;
            throw (
              websiteText('cart__available_stock', {
                amount,
              }).value || 'cart__available_stock'
            );
          } else {
            throw websiteText('cart__set_quantity_error').value;
          }
        }

        const afterRow = {
          ...result.cart.rows?.find((r: { id: string }) => r.id == rowId),
        };

        handleAnalytics(beforeRow, afterRow);

        return result;
      } finally {
        requestPending.value = false;
      }
    },
    requestPending,
  };
}
