export const PROJECT = 'GOODS_LABEL';
export const COMMENT = 'COMMENT';
export const TEMPORARY_ADDRESSES = 'TEMPORARY_ADDRESSES';
export const EDIT_DELIVERY_ADDRESS = 'EDIT_DELIVERY_ADDRESS';
export const EDIT_CONTACT_PERSON = 'EDIT_CONTACT_PERSON';

export const DELIVERY_PREFERENCE = {
  SPLIT: 'SPLIT',
  SINGLE_DELIVERY: 'SINGLE_DELIVERY',
  USER_DEFINED: 'USER_DEFINED',
};

export const PREVIOUS_URL = 'PREVIOUS_URL';

export const ERRORS = {
  NOT_ENOUGH_STOCK: 'NOT_ENOUGH_STOCK',
  NO_DELIVERY_ADDRESS: 'NO_DELIVERY_ADDRESS',
  NO_CONTACT_PERSON: 'NO_CONTACT_PERSON',
  NO_DELIVERY_COUNTRY: 'NO_DELIVERY_COUNTRY',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  CART_CHANGED: 'CART_CHANGED',
};