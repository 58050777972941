import { inject, ref, InjectionKey, Ref, provide, readonly } from "vue";
export const reviewVariantKey: InjectionKey<Ref<any>> = Symbol("reviewVariant");
export const editReviewContextKey: InjectionKey<Ref<IEditReviewContext>> =
  Symbol("editReviewContext");

export default function () {
  const selectedVariant = ref();
  const editReviewContext = ref<IEditReviewContext>();

  provide(reviewVariantKey, readonly(selectedVariant));
  provide(editReviewContextKey, readonly(editReviewContext));
  
  function setSelectedVariant(data: any) {
    selectedVariant.value = data;
  }

  function setEditReviewContext(value: IEditReviewContext) {
    editReviewContext.value = value;
  }

  return {
    setSelectedVariant,
    setEditReviewContext,
  };
}

export function getReviewContext() {
  return {
    selectedVariant: inject(reviewVariantKey, ref<any>(<any>{})),
    editReviewContext: inject(editReviewContextKey, ref<IEditReviewContext>()),
  };
}

export interface IEditReviewContext {
  reviewId: string;
  itemId: string;
  review: string;
}
