import { ref, computed } from 'vue';

import { fetchPage } from '@drapejs/core';
import * as bellaliteUser from '@/connectors/user';

import useUser from '@/composables/useUser';
import useContext from '@/composables/useContext';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import useExecuteCommand from '@/composables/useExecuteCommand';

import { ERRORS } from './constants';

export default function () {
  const { user } = useUser();
  const { navigateToLoginPage } = useContext();
  const { websiteText } = useWebsiteTexts();
  const { executeCommand } = useExecuteCommand();

  const requestPending = ref(false);

  const currentOrganizationId = ref(
    user.value?.organization?.organizationId || ''
  );

  const organizations = computed(() => [...(user.value?.organizations || [])].map((o) => ({
    ...o,
    value: o.organizationId,
    text: o.organizationName,
  })));

  async function changeOrganization(organization: any) {
    try {
      requestPending.value = true;
      const response = await executeCommand(
        bellaliteUser.commands.changeOrganization,
        {
          organizationId: organization.value,
        }
      );

      if (response.error != 'NONE') {
        if (response.error == ERRORS.NOT_AUTHENTICATED) {
          navigateToLoginPage();
        }
      } else {
        await executeCommand(fetchPage);
      }
    } catch (err) {
      console.error(err);
      throw websiteText('my_pages__change_organization_error').value;
    } finally {
      requestPending.value = false;
    }
  }

  return {
    currentOrganizationId,
    requestPending,
    organizations,
    changeOrganization,
  };
}
