export default (bcUsDate: string): string => {
  if (!bcUsDate) return '';

  const [month, day, year] = bcUsDate.split('/');

  // sometimes BC returns no date
  if (typeof month === 'undefined' || typeof day === 'undefined' || typeof year === 'undefined') return '';

  const fullYear = `20${year}`;
  return `${fullYear}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};
