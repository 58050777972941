import { inject, ref } from "vue";
import { invokeKey } from "@drapejs/core";
import * as rentals from "@/connectors/rentals";
import useUser from "./useUser";

export default function () {
  const invoke: any = inject(invokeKey);
  const { user } = useUser();

  const requestPending = ref(false);

  function buildRequestData(articleNumber: string = ""): any {
    return {
      url: window.location.href,
      organizationId: user.value?.organization?.organizationId,
      articleNumber,
    };
  }

  async function rentProduct(articleNumber: string) {
    try {
      requestPending.value = true;

      const res = await invoke(
        rentals.commands.rentProduct,
        buildRequestData(articleNumber)
      );
      return res;
    } finally {
      requestPending.value = false;
    }
  }

  async function stopRentingProduct(articleNumber: string) {
    try {
      requestPending.value = true;

      const res = await invoke(
        rentals.commands.stopRentingProduct,
        buildRequestData(articleNumber)
      );
      return res;
    } finally {
      requestPending.value = false;
    }
  }

  async function getCompaniesRentingProduct(articleNumber: string) {
    try {
      requestPending.value = true;

      const res = await invoke(
        rentals.commands.getCompaniesRentingProduct,
        buildRequestData(articleNumber)
      );
      return res;
    } finally {
      requestPending.value = false;
    }
  }

  async function fetchMyRentals() {
    try {
      requestPending.value = true;

      const res =
        (await invoke(rentals.commands.getRentedProducts, buildRequestData()))
          .items || [];

      return res.map((r: any) => {
        const s = { ...r };
        s.isRented = true;
        return s;
      });
    } finally {
      requestPending.value = false;
    }
  }

  return {
    fetchMyRentals,
    rentProduct,
    stopRentingProduct,
    getCompaniesRentingProduct,
    requestPending,
  };
}
