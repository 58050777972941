import {
  inject,
  ref,
  InjectionKey,
  Ref,
  provide,
  computed,
  readonly,
  onMounted,
  onBeforeUnmount,
  watch,
} from "vue";

import {
  getServerItemKey,
  getItemKey,
  subscribeKey,
  unsubscribeKey,
} from "@drapejs/core";

import useContext from "@/composables/useContext";
import useTycka from "@/composables/useTycka";
import useEventListener from "@/composables/useEventListener";

export const reviewerProfileKey: InjectionKey<Ref<any>> =
  Symbol("reviewerProfile");

export const reviewerReviewsKey: InjectionKey<Ref<any>> =
  Symbol("reviewerReviews");

export const loadMoreReviewerReviewsKey: InjectionKey<() => void> = Symbol(
  "loadMoreReviewerReviews"
);

const reviewsTakeCount = 6;

export default function () {
  const { route, settings, page } = useContext();
  const { useEmitterEventListener } = useEventListener();
  const { getReviewsByAuthor } = useTycka();

  const reviewerPageUrl = computed(() => {
    const url = settings.value?.reviewer?.cached_url || "";
    return url ? "/" + url : "";
  });

  const cache = {
    getServerItem: inject(getServerItemKey, () => null),
    getItem: inject(getItemKey, () => Promise.resolve(null)),
    subscribe: inject(subscribeKey, () => Promise.resolve()),
    unsubscribe: inject(unsubscribeKey, () => Promise.resolve()),
  };

  let currentCacheKey = getReviewerCacheKey(route.query?.reviewer_id || "");
  const reviewerProfile = ref(cache.getServerItem(currentCacheKey));
  provide(reviewerProfileKey, readonly(reviewerProfile));
  provide(loadMoreReviewerReviewsKey, loadMoreReviews);

  let reviewsTake = reviewsTakeCount;
  const reviews = ref();
  const requestPending = ref(false);
 
  const reviewHits = computed(() => reviews.value?.hits || []);
  const canLoadMore = computed(() => {
    return reviews.value?.total && reviews.value?.total > reviewHits.value.length;
  });

  function setReviewerProfile(data: any) {
    if (data) {
      reviewerProfile.value = data;
    }
  }

  async function loadReviewsByAuthor() {
    try {
      requestPending.value = true;
      reviews.value = null;
      const response = await getReviewsByAuthor({
        authorId: reviewerProfile.value?.authorId,
        take: reviewsTake,
      });
      reviews.value = response;
    } finally {
      requestPending.value = false;
    }
  }

  async function loadMoreReviews() {
    if (requestPending.value) return;
    reviewsTake += reviewsTakeCount;
    await loadReviewsByAuthor();
  }

  useEmitterEventListener(
    "Tycka:Review:ReviewSubmit:Close",
    loadReviewsByAuthor
  );

  onMounted(async () => {
    watch(
      () => reviewerProfile.value,
      async (val: any) => {
        if (val) {
          await loadReviewsByAuthor();
        }
      }
    );

    watch(
      () => route.query,
      async () => {
        if (currentCacheKey) {
          cache.unsubscribe(currentCacheKey, setReviewerProfile);
        }

        currentCacheKey = getReviewerCacheKey(route.query?.reviewer_id || "");
        cache.subscribe(currentCacheKey, setReviewerProfile, {
          immediate: true,
        });
      },
      { immediate: true }
    );
  });

  onBeforeUnmount(() => {
    cache.unsubscribe(currentCacheKey, setReviewerProfile);
  });

  return {
    reviewerPageUrl,
    reviewerProfile,
    reviews,
    loadMoreReviews,
    reviewHits,
    canLoadMore
  };
}

export function getReviewerContext() {
  return {
    reviewerProfile: inject(reviewerProfileKey, ref<any>(<any>{})),
    reviews: inject(reviewerReviewsKey, ref<any>(<any>{})),
    loadMoreReviews: inject(loadMoreReviewerReviewsKey, () => null),
  };
}

export function getReviewerCacheKey(reviewerId: string) {
  return `reviewer:${reviewerId}`;
}
