import { onMounted, onBeforeUnmount } from "vue";
import useContext from "@/composables/useContext";

export default function () {
  const { mitt } = useContext();

  function useGlobalEventListener(target: any, event: any, callback: any) {
    onMounted(() => target.addEventListener(event, callback));
    onBeforeUnmount(() => target.removeEventListener(event, callback));
  }

  function useWindowEventListener(
    event: any,
    callback: any,
    executeOnMounted: boolean,
    args?: any
  ) {
    onMounted(() => {
      if (executeOnMounted) {
        args ? callback(args) : callback();
      }
      window.addEventListener(event, callback);
    });
    onBeforeUnmount(() => window.removeEventListener(event, callback));
  }

  function useEmitterEventListener(event: any, callback: any) {
    onMounted(() => mitt.on(event, callback));
    onBeforeUnmount(() => mitt.off(event, callback));
  }

  return {
    useGlobalEventListener,
    useWindowEventListener,
    useEmitterEventListener,
  };
}
