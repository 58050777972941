const drapejs = require('@drapejs/core');

const environment = drapejs.getServerItem('__environment') || {
  storyblokToken: process.env.STORYBLOK_TOKEN,
  maxStoryblokFolderDepth: process.env.MAX_STORYBLOK_FOLDER_DEPTH,
  litiumBaseUrl: process.env.LITIUM_URL,
  siteId: process.env.SITE_ID,
  tyckaUrl: process.env.TYCKA_URL,
  tyckaAccount: process.env.TYCKA_ACCOUNT,
  gtmContainerId: process.env.GTM_CONTAINER_ID,
};

module.exports = environment;