import { inject, ref } from "vue";
import { invokeKey } from "@drapejs/core";

import * as user from "@/connectors/user";

import useContext from './useContext';
import useWebsiteTexts from "@/composables/useWebsiteTexts";

export default function () {
  const { navigateToLoginPage } = useContext();
  const { websiteText } = useWebsiteTexts();

  const invoke = inject(invokeKey);

  const requestPending = ref(false);

  async function deleteShippingAddress({ no, code, systemId }: any) {
    try {
      requestPending.value = true;

      const { error } = await invoke?.(user.commands.deleteShippingAddress, {
        no,
        code,
        systemId,
        url: location.href,
      });

      if (error !== "NONE") {
        if(error === 'NOT_AUTHENTICATED') {
          navigateToLoginPage();
          return;
        }
        throw websiteText("my_pages__delete_address_error").value;
      }
    } finally {
      requestPending.value = false;
    }
  }

  return {
    requestPending,
    deleteShippingAddress,
  };
}
