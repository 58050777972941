import {
  ref,
  Ref,
  watch,
} from 'vue';

if (typeof window !== 'undefined') {
  window.addEventListener('scroll', onScroll);
}

const state = ref(false);

let lastPosition = 0;
let origin = 0;
function onScroll() {

  if (turnedBackUpTowardsOrigin()) {
    origin = window.scrollY;
    state.value = false;
  }
  else if (turnedBackDownTowardsOrigin()) {
    origin = window.scrollY;
  }

  if (scrolledPastOrigin()) {
    state.value = true;
  }

  lastPosition = window.scrollY;

  function turnedBackUpTowardsOrigin() {
    return lastPosition > window.scrollY && origin < lastPosition;
  }

  function turnedBackDownTowardsOrigin() {
    return lastPosition < window.scrollY && origin > lastPosition;
  }

  function scrolledPastOrigin() {
    return origin + 100 < window.scrollY;
  }
}

export default function(intent: Ref<boolean>) {

  watch(state, (val) => intent.value = val);
  
}