import { inject } from "vue";
import {
  subscribeKey,
  unsubscribeKey,
  getItemKey,
  getServerItemKey,
} from "@drapejs/core";

export default function () {
  return {
    subscribe: inject(subscribeKey, () => {
      return null as any;
    }),
    unsubscribe: inject(unsubscribeKey, () => {
      return null as any;
    }),
    getItem: inject(getItemKey, () => {
      return null as any;
    }),
    getServerItem: inject(getServerItemKey, () => {
      return null as any;
    }),
  };
}
