import { differenceInCalendarDays, isBefore, isSameDay } from 'date-fns';

import useContext from './useContext';
import useFormatting from './useFormatting';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

interface StockInfo {
  time: string;
  websiteTextType: 'inStock' | 'today' | 'supplier' | 'days' | 'weeks'| 'week' | 'date';
  moreInfo?: StockInfo;
}

type WebsiteTextPrefix =
  | 'product'
  | 'checkout'
  | 'checkout_short'
  | 'quantity_input'
  | 'product_card'
  | 'product_short';

export default function () {
  const { cart } = useContext();
  const { formatDate } = useFormatting();
  const { websiteText } = useWebsiteTexts();

  const getCanBeOrderedFromSupplier = (variant: any) =>
    variant?.stock?.deliveryDateFromSupplier;

  const getCurrentStock = (variant: any) => ({
    ...(variant?.stock?.currentStock || {}),
    stockLabel: { time: '', websiteTextType: 'inStock' },
    deliveryLabel: { time: '', websiteTextType: 'today' },
    physicalStock: true,
  });

  const getIncomingStockItems = (variant: any) => {
    const incomingStock = variant?.stock?.incomingStock || [];
    return incomingStock.map((s: any) => {
      const date = new Date(s.date);
      const o = { ...s };
      o.stockLabel = {
        time: formatDate(date, 'yyyy-MM-dd'),
        websiteTextType: 'date',
      };
      o.deliveryLabel = { time: o.stockLabel.time, websiteTextType: 'date' };
      o.incomingStock = true;
      return o;
    });
  };

  const getAllStockItems = ({
    variant,
    quantity,
    subtractQuantityFromCart,
  }: any) => {
    const stockItems = [...getIncomingStockItems(variant)] || [];
    const currentStock = getCurrentStock(variant);

    if (stockItems.length > 0) {
      stockItems.sort((a, b) => a.amount - b.amount);
      stockItems.forEach((s) => (s.isActive = false));

      if (currentStock) {
        stockItems.unshift(currentStock);
      }
    } else {
      if (currentStock) {
        stockItems.unshift(currentStock);
      }
    }

    if (!variant.inactive && variant?.stock?.deliveryDateFromSupplier) {
      const supplierDate = new Date(variant.stock.deliveryDateFromSupplier);
      const shippingDate = new Date(
        variant?.stock?.expectedShippingDateTime || new Date()
      );
      const renderedDate = isBefore(shippingDate, supplierDate)
        ? supplierDate
        : shippingDate;

      const days: any =
        Math.abs(differenceInCalendarDays(new Date(), renderedDate)) || 1;
      const weeks = parseInt(<any>(days / 7), 10);

      stockItems.push({
        systemId: 'supplier',
        amount: 99999,
        supplierStock: true,
        stockLabel: { time: '', websiteTextType: 'supplier' },
        deliveryDate:
          weeks > 0
            ? { time: weeks, websiteTextType: weeks > 1 ? 'weeks' : 'week' }
            : { time: days, websiteTextType: 'days' },
      });
    }

    if (subtractQuantityFromCart) {
      const cartAmount = getCartStockAmount(variant);
      if (cartAmount > 0) {
        stockItems.forEach((s) => {
          s.amount -= cartAmount;
          if (s.amount < 0) {
            s.amount = 0;
          }
        });
      }
    }

    stockItems.some((s) => {
      if (s.amount >= (quantity || variant?.quantity || 1)) {
        return (s.isActive = true);
      }
    });

    return stockItems;
  };

  const getActiveStockItem = (variant: any, quantity = null) => {
    const stockItems =
      (quantity
        ? getAllStockItems({ variant, quantity })
        : getAllStockItems({ variant })) || [];
    return stockItems.find((s) => s.isActive);
  };

  const pdpStockStatusText = (
    variant: any,
    hideInStock = false,
    quantity = null
  ) => {
    const activeStockItem = getActiveStockItem(variant, quantity);
    if (!activeStockItem) return '';
    if (activeStockItem.physicalStock) {
      return !hideInStock ? activeStockItem.stockLabel : {};
    }
    if (activeStockItem.deliveryLabel) {
      return activeStockItem.deliveryLabel;
    }
    if (activeStockItem.deliveryDate) {
      return {
        time: '',
        websiteTextType: 'date',
        moreInfo: activeStockItem.deliveryDate,
      };
    }
    return '';
  };

  const plpStockStatusText = (product: any) => {
    const stockStatus = product.stockStatus;
    if (!stockStatus) return '';

    switch (stockStatus) {
      case 'ALL_IN_STOCK':
        return websiteText('product__all_in_stock').value;
      case 'SOME_IN_STOCK':
        return websiteText('product__some_in_stock').value;
      case 'ON_ORDER':
        return websiteText('available_on_order').value;
      default:
        return '';
    }
  };

  const getEstimatedShippingDateTimeLabel = (variant: any, quantity = null) => {
    const today = new Date();
    const activeStockItem = getActiveStockItem(variant, quantity);
    let estimatedShippingDate = activeStockItem?.deliveryDate;

    if (!estimatedShippingDate) {
      estimatedShippingDate = variant?.stock?.expectedShippingDateTime
        ? new Date(
            activeStockItem.date || variant.stock.expectedShippingDateTime
          )
        : today;

      if (isSameDay(today, estimatedShippingDate)) {
        return { time: '', websiteTextType: 'today' };
      }

      const date = estimatedShippingDate
        ? `${formatDate(new Date(estimatedShippingDate), 'yyyy-MM-dd')}`
        : '';
      return { time: date, websiteTextType: 'date' };
    }
    return {
      time: '',
      websiteTextType: 'date',
      moreInfo: estimatedShippingDate,
    };
  };

  const getWebsiteStringForStockItem = (
    stockInfo: StockInfo,
    stringPrefix: WebsiteTextPrefix
  ): string => {
    if (!stockInfo?.websiteTextType || !stringPrefix) return '';

    const type = stockInfo.websiteTextType;
    const onOrder = type === 'date' || type === 'weeks' || type === 'week' || type === 'days';

    if (stringPrefix.includes('short') && onOrder) {
      return websiteText(`${stringPrefix}__delivery_from_supplier`, {
        date: stockInfo.time,
      }).value;
    }

    if (stockInfo.moreInfo) {
      const dateString = getWebsiteStringForStockItem(
        stockInfo.moreInfo,
        stringPrefix
      );

      return websiteText(`${stringPrefix}__available_date`, {
        date: dateString,
      }).value;
    }

    switch (type) {
      case 'inStock':
        return websiteText(`${stringPrefix}__in_stock`).value;

      case 'today':
        return websiteText(`${stringPrefix}__available_today`).value;

      case 'supplier':
        return websiteText(`${stringPrefix}__delivery_from_supplier`).value;

      case 'date':
        return websiteText(`${stringPrefix}__available_date`, {
          date: stockInfo.time,
        }).value;

      case 'weeks':
        return websiteText(`${stringPrefix}__delivery_from_supplier_weeks`, {
          weeks: stockInfo.time,
        }).value;

      case 'week':
        return websiteText(`${stringPrefix}__delivery_from_supplier_week`, {
          weeks: stockInfo.time,
        }).value;

      case 'days':
        return websiteText(`${stringPrefix}__delivery_from_supplier_days`, {
          days: stockInfo.time,
        }).value;

      default:
        return '';
    }
  };

  const getCheckoutAvailableFromLabel = ({
    text = 'checkout__delivery_date_info',
    replacementTokenValue,
    displayOnSeparateRows
  }: any) => {
    return `<span class="checkout__available-from-caption"> 
    ${
      websiteText(text, {
        date: displayOnSeparateRows
          ? `<br/> <span class="checkout__available-from-date"> ${replacementTokenValue} </span>`
          : replacementTokenValue,
      }).value
    } 
  </span>`;
  }

  const getCheckoutRowStockStatusText = (variant: any, displayOnSeparateRows: boolean = false, isUserDefinedDelivery: boolean = false) => {
    const quantity = variant.useNonSplitQuantity
    ? variant.quantity
    : variant.splitQuantity || variant.quantity;

    const activeStockItem = getActiveStockItem(variant, quantity);

    let estimatedShippingDate = activeStockItem?.deliveryDate;

    if (isUserDefinedDelivery) {
      if(activeStockItem.supplierStock){
        const dateString = getWebsiteStringForStockItem(estimatedShippingDate, 'checkout');
        return getCheckoutAvailableFromLabel({
          replacementTokenValue: dateString,
          displayOnSeparateRows
        });
      }
      
      const userDefinedDate = cart.value?.expectedShippingDateTime
        ? new Date(cart.value.expectedShippingDateTime)
        : null;

      const activeStockItemDate = activeStockItem?.date || cart.value?.shippingDateTime
       ? new Date(activeStockItem?.date || cart.value?.shippingDateTime)
       : null;

      const date = userDefinedDate && activeStockItemDate
       ? isBefore(activeStockItemDate, userDefinedDate) ? userDefinedDate : activeStockItemDate
       : userDefinedDate;
       
      return getCheckoutAvailableFromLabel({
        replacementTokenValue: date ? formatDate(date, 'yyyy-MM-dd') : '',
        displayOnSeparateRows
      });
    } else if (!estimatedShippingDate) {
      estimatedShippingDate =
        new Date(activeStockItem?.date || cart.value?.shippingDateTime) ||
        new Date();
      return getCheckoutAvailableFromLabel({
        replacementTokenValue: `${formatDate(estimatedShippingDate, 'yyyy-MM-dd')}`,
        displayOnSeparateRows
      });
    } else {
      return getCheckoutAvailableFromLabel({
        replacementTokenValue: getWebsiteStringForStockItem(estimatedShippingDate, 'checkout'),
        displayOnSeparateRows
      });
    }
  };

  function getCartStockAmount(variant: any) {
    const cartAmount = (cart.value?.rows || [])
      .filter(
        (e: any) =>
          e.articleNumber == variant.id ||
          e.articleNumber == variant.articleNumber
      )
      .reduce((sum: number, e: any) => {
        sum += e.quantity;
        return sum;
      }, 0);
    return cartAmount;
  }

  return {
    getAllStockItems,
    getIncomingStockItems,
    getActiveStockItem,
    pdpStockStatusText,
    plpStockStatusText,
    getEstimatedShippingDateTimeLabel,
    getCanBeOrderedFromSupplier,
    getWebsiteStringForStockItem,
    getCheckoutRowStockStatusText,
    getCheckoutAvailableFromLabel
  };
}
