import {
  ref,
  onMounted,
  watch,
  provide,
  inject,
  computed,
} from 'vue';

import * as customer from '@/connectors/customer-prices';

import useUser from '@/composables/useUser';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import useExecuteCommand from './useExecuteCommand';
import useCache from './useCache';

const requestPending = ref<{ [key: string]: boolean }>({});
const transactionError = ref<{ [key: string]: string }>({});
const customerPrices = ref<{ [key: string]: VariantPrice[] }>({});

export default function () {
  const { user, pendingAuth } = useUser();
  const { websiteText } = useWebsiteTexts();
  const { executeCommand } = useExecuteCommand();
  const { getItem } = useCache();

  provide('customerPricesRequestPending', requestPending);
  provide('customerPrices', customerPrices);
  provide('customerPricesTransactionError', transactionError);

  const waitForCustomerPrices = computed(
    () => user.value.isAuthenticated || pendingAuth.value
  );
  provide('waitForCustomerPrices', waitForCustomerPrices);

  async function fetchPricesForProduct(variantId: string) {
    await fetchPricesForProducts([variantId]);
  }

  async function fetchPricesForProducts(variantIds: string[]) {
    try {
      if (
        variantIds.every((v) => customerPrices.value[v]) ||
        variantIds.some((v) => requestPending.value[v]) ||
        !user.value.isAuthenticated
      ) {
        return;
      }

      variantIds.forEach((v) => {
        requestPending.value[v] = true;
        delete transactionError.value[v];
      });

      const { error: generalError, priceBuckets: allPrices } =
        await executeCommand(customer.commands.getCustomerPrices, {
          variantIds: variantIds,
          url: location.href,
        });

      if (generalError !== 'NONE') {
        switch (generalError) {
          case 'UNAUTHORIZED':
            break;
          default:
            variantIds.forEach((v) => {
              transactionError.value[v] = websiteText(
                'global__fetch_customer_prices_error'
              ).value;
            });
            break;
        }
        return;
      }

      variantIds.forEach((v) => {
        const variantPrice = allPrices[v];
        if (!variantPrice) {
          transactionError.value[v] = websiteText(
            'global__missing_variant_customer_price_error'
          ).value;

          return;
        }

        const { error: variantError, prices } = variantPrice;
        if (variantError) {
          transactionError.value[v] = websiteText(
            'global__unknown_variant_customer_price_error'
          ).value;
        }
        customerPrices.value[v] = prices;
      });
    } finally {
      variantIds.forEach((v) => {
        requestPending.value[v] = false;
      });
    }
  }

  function reset(variantId: string) {
    delete customerPrices.value[variantId];
    delete transactionError.value[variantId];
  }

  onMounted(() => {
    watch(
      () => user.value?.companyName,
      (newValue, prevValue) => {
        if (!newValue || newValue != prevValue) {
          transactionError.value = {};
          customerPrices.value = {};
        }
      },
      {
        immediate: true,
      }
    );
  });

  return {
    requestPending,
    transactionError,
    customerPrices,
    fetchPricesForProduct,
    fetchPricesForProducts,
    reset,
  };
}

export function getCustomerPriceContext() {
  const requestPending = inject(
    'customerPricesRequestPending',
    ref<{ [key: string]: boolean }>({})
  );

  const customerPrices = inject(
    'customerPrices',
    ref<{ [key: string]: VariantPrice[] }>({})
  );

  const customerPricesTransactionError = inject(
    'customerPricesTransactionError',
    ref<{ [key: string]: string }>({})
  );

  return {
    requestPending: requestPending,
    customerPrices: customerPrices,
    transationError: customerPricesTransactionError,
    waitForCustomerPrices: inject('waitForCustomerPrices', ref(false)),
  };
}

export interface VariantPrice {
  price: number;
  quantity: number;
}
