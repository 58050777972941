import { defineReceiver, defineCommand } from "@drapejs/invoker";
import { gql } from "graphql-request";
import { request, query } from "@distancify/drapejs-litium";
import { Invoker } from "@drapejs/invoker";

export const commands = {
  getCompaniesRentingProduct: defineCommand<{
    url: string;
    articleNumber: number;
    cacheKey?: string;
  }>("getCompaniesRentingProduct"),
  getRentedProducts: defineCommand<{
    url: string;
    organizationId: number;
    cacheKey?: string;
  }>("getRentedProducts"),
  getRentalState: defineCommand<{
    url: string;
    articleNumber: number;
    cacheKey?: string;
  }>("getRentalState"),
  rentProduct: defineCommand<{
    url: string;
    organizationId: string;
    articleNumber: number;
    cacheKey?: string;
  }>("rentProduct"),
  stopRentingProduct: defineCommand<{
    url: string;
    organizationId: string;
    articleNumber: number;
    cacheKey?: string;
  }>("stopRentingProduct"),
};

export const receivers = {
  getCompaniesRentingProduct: defineReceiver(
    commands.getCompaniesRentingProduct,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          query companiesRentingProduct($url: String!, $articleNumber: String) {
            session(url: $url) {
              rentals {
                companiesRentingProduct(articleNumber: $articleNumber) {
                  city
                  phone
                  name
                  website
                }
              }
            }
          }
        `),
        {
          url: command.url,
          articleNumber: command.articleNumber,
        }
      );

      return {
        items: result.session.rentals.companiesRentingProduct,
      };
    },
    "litium"
  ),
  getRentedProducts: defineReceiver(
    commands.getRentedProducts,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          query RentedProducts($url: String!, $organizationId: String) {
            session(url: $url) {
              rentals {
                rentedProducts(organizationId: $organizationId) {
                  url
                  articleNumber
                  brand
                  name
                  imageId
                }
              }
            }
          }
        `),
        {
          url: command.url,
          organizationId: command.organizationId,
        }
      );

      return {
        items: result.session.rentals.rentedProducts,
      };
    },
    "litium"
  ),
  getRentalState: defineReceiver(
    commands.getRentalState,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          query RentedProducts($url: String!, $articleNumber: String) {
            session(url: $url) {
              rentals {
                productRentalState(articleNumber: $articleNumber) {
                  isRentedByCurrentOrganization
                  isRentedByAnyOrganization
                }
              }
            }
          }
        `),
        {
          url: command.url,
          articleNumber: command.articleNumber,
        }
      );

      return result.session.rentals.productRentalState;
    },
    "litium"
  ),
  rentProduct: defineReceiver(
    commands.rentProduct,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation rentProduct(
            $url: String!
            $organizationId: String
            $articleNumber: String
          ) {
            session(url: $url) {
              rentProduct(
                organizationId: $organizationId
                articleNumber: $articleNumber
              ) {
                error
                productRentalState {
                  isRentedByCurrentOrganization
                  isRentedByAnyOrganization
                }
              }
            }
          }
        `),
        {
          url: command.url,
          organizationId: command.organizationId,
          articleNumber: command.articleNumber,
        }
      );

      return result.session.rentProduct;
    },
    "litium"
  ),
  stopRentingProduct: defineReceiver(
    commands.stopRentingProduct,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation stopRentingProduct(
            $url: String!
            $organizationId: String
            $articleNumber: String
          ) {
            session(url: $url) {
              stopRentingProduct(
                organizationId: $organizationId
                articleNumber: $articleNumber
              ) {
                error
                productRentalState {
                  isRentedByCurrentOrganization
                  isRentedByAnyOrganization
                }
              }
            }
          }
        `),
        {
          url: command.url,
          organizationId: command.organizationId,
          articleNumber: command.articleNumber,
        }
      );

      return result.session.stopRentingProduct;
    },
    "litium"
  ),
};

export function configureRentalCommands(invoker: Invoker) {
  invoker
    .configureCommand(commands.getCompaniesRentingProduct)
    .addReceiver(receivers.getCompaniesRentingProduct);

  invoker
    .configureCommand(commands.getRentedProducts)
    .addReceiver(receivers.getRentedProducts);

  invoker
    .configureCommand(commands.rentProduct)
    .addReceiver(receivers.rentProduct);

  invoker
    .configureCommand(commands.stopRentingProduct)
    .addReceiver(receivers.stopRentingProduct);
}
