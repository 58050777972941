import Worker from 'worker-loader!./web-worker'
import createApp from './main'

import './style/white/index.css'

import EComHeader from './components/EComHeader';
import EComFooter from './components/EComFooter';
import { EnvironmentOptions } from './EnvironmentOptions';

export default function(environmentOptions: EnvironmentOptions, workerFactory?: () => Worker) {
  return createApp(
    environmentOptions,
    {
      header: EComHeader,
      footer: EComFooter,
    },
    workerFactory);
}