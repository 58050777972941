import { defineCommand, defineReceiver, Invoker } from "@drapejs/invoker";
import { fetchPage } from "@drapejs/core";
import { batch, request, query } from "@distancify/drapejs-litium";
import { gql } from "graphql-request";

import { getReviewerCacheKey } from "@/composables/useReviewer";

function isReviewerPage(data: any) {
  const component = data?.component?.toLowerCase();
  return component && component.indexOf("reviewer") >= 0;
}

export const scheduleGetReviewer = defineReceiver(
  fetchPage,
  async function (command, data) {
    if (!isReviewerPage(data)) {
      return data;
    }

    const reviewerId = command.query.reviewer_id || "";
    await this.invoke(batch.scheduleQuery, {
      cacheKey: getReviewerCacheKey(reviewerId),
      query: gql`
        reviewer(reviewerId: $reviewerId) {
          authorId
          firstName
          lastName
          title
          bio
          interview
          profileImageId
          numberOfReviews
        }`,
      variables: {
        reviewerId: {
          value: reviewerId,
          type: "Guid",
        },
      },
    });
    return data;
  }
);

export const commands = {
  getReviewer: defineCommand<{
    url: string;
    reviewerId: string;
  }>("getReviewer"),
  getReviewsStatus: defineCommand<{
    orderId: string;
    reviewerId: string;
  }>("getReviewsStatus"),
  updateReview: defineCommand<{
    reviewId: string;
    review: string;
  }>("updateReview"),
  privateReviewsByAuthor: defineCommand<{
    authorId: string;
    take: number;
  }>("privateReviewsByAuthor"),
};

export const receivers = {
  getReviewer: defineReceiver(
    commands.getReviewer,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          query getReviewer($url: String!, $reviewerId: String!) {
            session(url: $url) {
              reviewer(reviewerId: $reviewerId) {
                firstName
                lastName
                profileImageId
                customerRole
                customerDetails
                interview
              }
            }
          }
        `),
        {
          url: command.url,
          reviewerId: command.reviewerId,
        }
      );

      return result.session.reviewer;
    },
    "litium"
  ),
  getReviewsStatus: defineReceiver(
    commands.getReviewsStatus,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          query getReviewsStatus(
            $url: String!
            $reviewerId: Guid!
            $orderId: String!
          ) {
            session(url: $url) {
              orderReviewStatus(reviewerId: $reviewerId, orderId: $orderId) {
                items
                authorId
                reviewedItems
                secret
              }
            }
          }
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          reviewerId: command.reviewerId,
          orderId: command.orderId,
        }
      );
      if (result.session.orderReviewStatus) {
        result.session.orderReviewStatus.id = command.orderId;
      }
      return result.session.orderReviewStatus;
    },
    "litium"
  ),
  updateReview: defineReceiver(
    commands.updateReview,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation reviews($url: String!, $reviewId: String!, $review: String!) {
            session(url: $url) {
              updateReview(reviewId: $reviewId, review: $review)
            }
          }
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          reviewId: command.reviewId,
          review: command.review,
        }
      );

      return {
        message: result.session.updateReview,
      };
    },
    "litium"
  ),
  privateReviewsByAuthor: defineReceiver(
    commands.privateReviewsByAuthor,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          query reviews($url: String!, $authorId: String, $take: Int) {
            session(url: $url) {
              privateAuthorReviews(authorId: $authorId, take: $take) {
                total
                hits {
                  id
                  item {
                    id
                    imageId
                    name
                    brandName
                    productUrls
                  }
                  transaction {
                    transactionId
                  }
                  productRating {
                    comment
                  }
                }
              }
            }
          }
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          authorId: command.authorId,
          take: command.take,
        }
      );

      return result.session.privateAuthorReviews;
    },
    "litium"
  ),
};

export function configureReviewsCommands(invoker: Invoker) {
  invoker
    .configureCommand(commands.getReviewsStatus)
    .addReceiver(receivers.getReviewsStatus);

  invoker
    .configureCommand(commands.getReviewer)
    .addReceiver(receivers.getReviewer);
  
    invoker
    .configureCommand(commands.privateReviewsByAuthor)
    .addReceiver(receivers.privateReviewsByAuthor);  

    invoker
    .configureCommand(commands.updateReview)
    .addReceiver(receivers.updateReview);  
}
