import { inject, ref } from "vue";
import { invokeKey, getItemKey } from "@drapejs/core";

import useContext from "@/composables/useContext";
import * as user from "@/connectors/user";

export default function () {
  const invoke: any = inject(invokeKey);
  const getItem: any = inject(getItemKey);

  const { channel, environmentOptions } = useContext();

  const requestPending = ref(false);

  async function savePersonalInfo({
    firstName,
    lastName,
    phoneNumber,
    title,
    bio,
  }: any) {
    try {
      requestPending.value = true;

      const res = await invoke(user.commands.updatePerson, {
        url: window.location.href,
        ...{
          firstName,
          lastName,
          phoneNumber,
          title,
          bio,
        },
      });
      return res;
    } finally {
      requestPending.value = false;
    }
  }

  async function uploadProfileImage(image: any) {
    const uploadedImage = await executeFetchRequest({
      requestPath: "upload-profile-image",
      method: "POST",
      requestBody: image,
    });
    return uploadedImage;
  }

  async function executeFetchRequest({
    requestPath,
    method,
    requestBody,
  }: any) {
    const requestData: any = {
      method,
      headers: {
        "litium-request-context": JSON.stringify(buildLitiumRequestContext()),
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(await buildUserAuthorizationHeaders()),
      },
      body: JSON.stringify({
        ...requestBody,
      }),
    };

    const endpoint = `${environmentOptions.litiumBaseUrl}/api/user-info/${requestPath}`;
    const response = await fetch(endpoint, requestData);
    if (!response.ok) {
      throw Error(response.statusText);
    }

    const result = await response.json();

    return result;
  }

  function buildLitiumRequestContext() {
    return {
      channelSystemId: channel.value.systemId,
      websiteSystemId: channel.value.website?.systemId,
      culture: channel.value.locale,
    };
  }

  async function buildUserAuthorizationHeaders() {
    var litium = await getItem("__litium");
    return {
      "LH-Session": litium.session,
      "LH-Uid": litium.uid || ''
    };
  }

  return {
    requestPending,
    uploadProfileImage,
    savePersonalInfo,
  };
}
