/* eslint-disable no-param-reassign */
import Vue, { createApp, createSSRApp, defineAsyncComponent } from "vue";
import drapejs, { OptionsComponent } from "@drapejs/core";
import Storyblok, { StoryblokOptions } from "@distancify/drapejs-storyblok";
import StandardComponents from "@distancify/storyblok-standard-components";
import { plugin as Litium } from "@distancify/drapejs-litium";
import { format, parse } from "date-fns";
import MarkdownIt from "markdown-it";
import consola from "consola";
import { dateLocales, buildUrlPathWithQuery } from "./helpers/utils";
import App from "./App.vue";

import { IntersectionObserverDirective } from "./directives/intersectionObserver";

import {
  cartKey,
  userKey,
  channelKey,
  categoryTreeKey,
  currentPageKey,
  textsKey,
  settingsKey,
  emitterKey,
} from "./keys";

import "./style/shared/index.css";
import { EnvironmentOptions, environmentKey } from './EnvironmentOptions';

consola.level = process.env.NODE_ENV == 'development' ? 5 : 3;

const tokenRegex = /\{{(.+?)\}}/gi;

function createMd() {
  const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    breaks: true,
  });

  md.renderer.rules.table_open = () => '<table class="markdown-table">\n';

  return md;
}

if (process.env.NODE_ENV == "development") {
  consola.level = 5;
}

export default function (environment: EnvironmentOptions, { header, footer }: any, workerFactory?: () => Worker) {
  let create = createSSRApp;

  const app = create(App)
    .use(drapejs, {
      pageComponents: {
        ContentPage: defineAsyncComponent(
          () => import("./pages/ContentPage.vue")
        ),
        NotFound: defineAsyncComponent(() => import("./pages/NotFound.vue")),
        Category: defineAsyncComponent(() => import("./pages/Category.vue")),
        Product: defineAsyncComponent(() => import("./pages/Product.vue")),
        Login: defineAsyncComponent(() => import("./pages/Login.vue")),
        Register: defineAsyncComponent(() => import("./pages/Register.vue")),
        Checkout: defineAsyncComponent(() => import("./pages/Checkout.vue")),
        Receipt: defineAsyncComponent(() => import("./pages/Receipt.vue")),
        BlogPost: defineAsyncComponent(() => import("./pages/BlogPost.vue")),
        BlogIndex: defineAsyncComponent(() => import("./pages/BlogIndex.vue")),
        BrandIndex: defineAsyncComponent(() => import("./pages/BrandIndex.vue")),
        Brand: defineAsyncComponent(() => import("./pages/Brand.vue")),
        Reviewer: defineAsyncComponent(() => import("./pages/Reviewer.vue")),
        OrderDetails: defineAsyncComponent(
          () => import("./pages/OrderDetails.vue")
        ),
        EComSettings: defineAsyncComponent(
          () => import("./pages/EComSettings.vue")
        ),
        ContactForm: defineAsyncComponent(
          () => import("./pages/ContactForm.vue")
        ),
        Installers: defineAsyncComponent(() => import("./pages/Installers.vue")),
      },
      routerIgnoreQueryParams: [/facets/],
      workerFactory,
    })
    .use(Storyblok, {
      blockComponents: {
        ...StandardComponents.blocks,
        Text: defineAsyncComponent(() => import("./blocks/Text")),
        Carousel: defineAsyncComponent(() => import("./blocks/Carousel")),
        NewsletterSignup: defineAsyncComponent(
          () => import("./blocks/NewsletterSignup")
        ),
        News: defineAsyncComponent(() => import("./blocks/News")),
        Video: defineAsyncComponent(() => import("./blocks/Video")),
        Button: defineAsyncComponent(() => import("./blocks/Button")),
        ButtonRepeater: defineAsyncComponent(
          () => import("./blocks/ButtonRepeater")
        ),
        Margin: defineAsyncComponent(() => import("./blocks/Margin")),
        Products: defineAsyncComponent(() => import("./blocks/Products")),
        CookieDeclaration: defineAsyncComponent(() => import("./blocks/CookieDeclaration")),
      },
      token: environment.storyblokToken,
      fetchDraft: process.env.NODE_ENV == "development",
    } as StoryblokOptions)
    .use(Litium as any, {
      baseUrl: environment.litiumBaseUrl,
      disableBlocks: true,
      blockComponents: {
        // Your block component names should match your block IDs in Litium
      },
    })
    .provide(environmentKey, environment)

    .component("SiteHeader", header)
    .component("SiteFooter", footer)
    .directive('intersection', IntersectionObserverDirective)
    .mixin({
      extends: OptionsComponent,
      inject: {
        $cartReactive: cartKey,
        $channelReactive: channelKey,
        $categoryTreeReactive: categoryTreeKey,
        $userReactive: userKey,
        $currentPageKeyReactive: currentPageKey,
        $globalTextsReactive: textsKey,
        $settingsReactive: settingsKey,
        $emitter: emitterKey,
      } as any,
      computed: {
        $cart() {
          return this.$cartReactive || {};
        },
        $channel() {
          return this.$channelReactive || {};
        },
        $categoryTree() {
          return this.$categoryTreeReactive || {};
        },
        $globalTexts() {
          return this.$globalTextsReactive || {};
        },
        $globalFields() {
          return (
            (this.$channelReactive &&
              this.$channelReactive?.website?.websiteFields) ||
            {}
          );
        },
        $user() {
          if (!this.$userReactive) {
            return {};
          }
          const user = { ...(this.$userReactive || {}) };
          user.isAuthenticated = this.$userReactive?.isAuthenticated || false;

          return user;
        },
        $settings() {
          return this.$settingsReactive;
        },
        $currentPageKey() {
          return this.$currentPageKeyReactive?.data || "";
        },
        $mitt() {
          return this.$emitter;
        },
      },
      methods: {
        $formatPrice(
          value: number,
          decimals?: number,
          locale?: string,
          currencyId?: string
        ) {
          if (!decimals) {
            decimals = 2;
          }
          if (value !== 0 && !value) {
            return "";
          }
          if (!this.$cart) {
            return value;
          }
          return new Intl.NumberFormat(
            locale || this.$channel?.locale || "sv-SE",
            {
              style: "currency",
              currency: currencyId || this.$cart.currency?.id || "SEK",
              maximumFractionDigits: decimals,
              minimumFractionDigits: 0,
            }
          ).format(value);
        },
        $toLitiumMediaUrl(
          id: string,
          dimensions?: {
            maxWidth: number;
            maxHeight: number;
            fillArea: {
              focalPoint?: { top: number; left: number };
              width: number;
              height: number;
            };
          }
        ) {
          const qs = [];
          if (dimensions?.maxWidth) {
            if (typeof window !== "undefined" && window.devicePixelRatio) {
              dimensions.maxWidth =
                dimensions.maxWidth &&
                Math.ceil(dimensions.maxWidth * window.devicePixelRatio);
            }

            qs.push(`maxWidth=${dimensions.maxWidth}`);
          }

          if (dimensions?.maxHeight) {
            if (typeof window !== "undefined" && window.devicePixelRatio) {
              dimensions.maxHeight =
                dimensions.maxHeight &&
                Math.ceil(dimensions.maxHeight * window.devicePixelRatio);
            }

            qs.push(`maxHeight=${dimensions.maxHeight}`);
          }

          if (dimensions?.fillArea) {
            const focalPoint = dimensions.fillArea.focalPoint
              ? `_${dimensions.fillArea.focalPoint.left}_${dimensions.fillArea.focalPoint.top}`
              : "";
            qs.push(
              `fillArea=${dimensions.fillArea.width}x${dimensions.fillArea.height}${focalPoint}`
            );
          }

          let imageUrl = `${environment.litiumBaseUrl}/api/media/image/${id}`;

          if (qs.length) {
            imageUrl += `?${qs.join("&")}`;
          }

          return imageUrl;
        },
        $renderMarkdown(text: string) {
          if (!text) {
            return "";
          }

          return createMd().render(text);
        },
        $renderMarkdownInline(text: string) {
          if (!text) {
            return "";
          }

          return createMd().renderInline(text);
        },
        $replaceTokens(text: string, args: any) {
          const replace = (textToFormat: string) => {
            if (!args) {
              args = {};
            }

            return textToFormat.replace(
              tokenRegex,
              (_, p1) =>
                args[p1.toLowerCase()] ||
                this.$globalTexts[p1.toLowerCase()] ||
                ""
            );
          };

          return !text ? text : replace(text);
        },
        $formatDate(date: Date, dateFormat: string) {
          const language: Locale = (dateLocales as any)[
            this.$cart?.locale || this.$channel?.locale || 'sv-SE'
          ] as Locale;
          return format(date, dateFormat, { locale: language });
        },
        $parseDate(date: string, dateFormat: string) {
          const language: Locale = (dateLocales as any)[
            this.$channel.locale || 'sv-SE'
          ] as Locale;
          return parse(date, dateFormat, new Date(), { locale: language });
        },
        $navigateToLoginPage(query = null) {
          const search = query || this.$route.query || {};
          const completeQuery = {
            ...search,
            redirect: encodeURIComponent(this.$route.pathname || ""),
          };
          if (this.$settings?.login?.cached_url) {
            const loginUrl = buildUrlPathWithQuery(
              "/" + this.$settings.login.cached_url,
              completeQuery
            );
            this.$navigate(loginUrl);
          } else if (this.$channel?.rootPath) {
            const rootPageUrl = buildUrlPathWithQuery(
              this.$channel.rootPath,
              completeQuery
            );
            this.$navigate(rootPageUrl);
          }
        },
        $navigateToRegisterPage(query = null) {
          const search = query || this.$route.query || {};
          const completeQuery = {
            ...search,
            redirect: encodeURIComponent(this.$route.pathname || ""),
          };
          if (this.$settings?.register?.cached_url) {
            const registerUrl = buildUrlPathWithQuery(
              "/" + this.$settings.register.cached_url,
              completeQuery
            );
            this.$navigate(registerUrl);
          } else if (this.$channel?.rootPath) {
            const rootPageUrl = buildUrlPathWithQuery(
              this.$channel.rootPath,
              completeQuery
            );
            this.$navigate(rootPageUrl);
          }
        },
      },
    });

  app.config.warnHandler = function (msg, vm, trace) {
    console.log(msg, vm, trace);
  };

  return app;
}
