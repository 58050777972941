export default () => {
  return {
    toUrl,
  };

  function toUrl(storyblokLink: { url: string; cached_url: string; anchor?: string; id: string }) {
    if (!storyblokLink || (!storyblokLink.id && !storyblokLink.url && !storyblokLink.cached_url)) return '';

    if (storyblokLink.url) {
      return storyblokLink.url;
    }

    const result = <string>storyblokLink.cached_url;
    if (!result) {
      return '/';
    }

    return '/' + result + (storyblokLink.anchor ? '#' + storyblokLink.anchor : '');
  }
};
