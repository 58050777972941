<script>
import ErrorMessage from './ErrorMessage.vue';

export default {
  components: {
    ErrorMessage,
  },
  data: () => ({
    inputType: '',
  }),
  props: {
    type: {
      type: String,
      default: 'text',
    },
    modelValue: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
    },
    errorMessage: {
      type: String,
    },
    showErrorMessage: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    min: {
      type: Number,
    },
    autofocus: {
      type: Boolean,
    },
    autoselect: {
      type: Boolean,
    },
    rows: {
      type: Number,
      default: 2,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    showCharactersCount: {
      type: Boolean,
      default: false,
    },
    hasCountError: {
      type: Boolean,
      default: false,
    },
    charactersCountText: {
      type: String,
    },
  },
  emits: [
    'update:modelValue',
    'input',
    'focus',
    'blur',
    'click',
    'keydown',
    'enter-key-pressed',
  ],
  computed: {
    isValid() {
      return !this.errorMessage;
    },
    isPasswordInput() {
      return this.type === 'password';
    },
    isPasswordHidden() {
      return this.inputType === 'password';
    },
    isInput() {
      return this.type !== 'textarea';
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.inputType = this.inputType === this.type ? 'text' : this.type;
    },
    focus() {
      this.$refs.input?.focus();
    },
    updateModelValue(value) {
      this.$emit('update:modelValue', value);
      this.$emit('input', value);
    },
    onFocus() {
      if (this.autoselect) {
        this.$refs.input?.select();
      }
    },
  },
  created() {
    this.inputType = this.type;
  },
  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.focus();
      });
    }
  },
};
</script>

<template>
  <div class="text-input">
    <div class="text-input-label">
      <label v-if="label" :for="id">{{ label }}</label>
      <img
        v-if="isPasswordInput && isPasswordHidden"
        src="../../static/icons/show-password-icon.svg"
        class="text-input__toggle-password-visibility"
        @click="togglePasswordVisibility()"
      />
      <img
        v-if="isPasswordInput && !isPasswordHidden"
        src="../../static/icons/hide-password-icon.svg"
        class="text-input__toggle-password-visibility"
        @click="togglePasswordVisibility()"
      />
    </div>
    <input
      :id="id"
      ref="input"
      v-if="isInput"
      class="text-input__input"
      :class="{ 'text-input__input--error': !isValid || hasCountError }"
      :type="inputType"
      :autocomplete="autocomplete"
      :value="modelValue"
      :placeholder="placeholder"
      @input="updateModelValue($event.target.value)"
      :min="min"
      @focus="
        $emit('focus');
        onFocus();
      "
      @blur="$emit('blur')"
      @click="$emit('click', $event)"
      @keydown="$emit('keydown', $event)"
      @keydown.enter="$emit('enter-key-pressed')"
    />
    <textarea
      v-else
      ref="input"
      class="text-input__input"
      :class="{ 'text-input__input--error': !isValid || hasCountError }"
      :type="type"
      :autocomplete="autocomplete"
      :value="modelValue"
      :placeholder="placeholder"
      :rows="rows"
      @input="updateModelValue($event.target.value)"
      @focus="
        $emit('focus');
        onFocus();
      "
      @blur="$emit('blur')"
      @click="$emit('click', $event)"
      @keydown="$emit('keydown', $event)"
      @keydown.enter="$emit('enter-key-pressed')"
    />
    <div
      class="text-input__information"
    >
      <error-message v-if="showErrorMessage" :message="errorMessage" />
      <div
        class="text-input__information-characters-count"
        :class="{
          'text-input__information-characters-count--error': hasCountError,
        }"
      >
        {{ charactersCountText }}
      </div>
    </div>
  </div>
</template>

<style>
.text-input-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-main-90);
}

.text-input__toggle-password-visibility {
  margin-left: auto;
}

.text-input__toggle-password-visibility {
  cursor: pointer;
  width: 15px;
  height: 15px;
}

textarea.text-input__input--error,
input[type='text'].text-input__input--error,
input[type='number'].text-input__input--error,
input[type='email'].text-input__input--error {
  border-color: var(--color-feedback-warn);
  color: var(--color-feedback-warn);
  transition: color 0.2s ease, border-color 0.2s ease;
  background-color: var(--color-pale-grey);
}

textarea.text-input__input--error:focus,
input[type='number'].text-input__input--error:focus,
input[type='text'].text-input__input--error:focus,
input[type='email'].text-input__input--error:focus {
  border-color: var(--color-text-medium);
  transition: border-color 0.2s ease;
}

.text-input__information {
  display: flex;
  justify-content: space-between;
}

.text-input__information-characters-count {
  color: var(--color-text-medium);
  font-size: var(--size-xxs);
  line-height: 16px;
  letter-spacing: 0.18px;
  margin-left: auto;
  margin-top: 4px;
}

.text-input__information-characters-count--error {
  color: var(--color-feedback-warn);
}
</style>
