<script>
import DropdownArrow from "./DropdownArrow";
import TextInput from "./TextInput";

export default {
  props: {
    selectedCountry: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    maxHeight: {
      type: Number,
      default: 300,
    },
    errorMessage: {
      type: String,
    },
    value: {
      type: String,
    },
    id: {
      type: String,
    },      
  },
  components: {
    DropdownArrow,
    TextInput,
  },
  emits: ["input", "keydown", "focus", "blur", "enter-key-pressed"],
  data() {
    return {
      showDropdown: false,
      selectedIndex: null,
      currentIndex: null,
    };
  },
  computed: {
    countryCodeText() {
      return this.$globalTexts.global__phone_country_code || 'global__phone_country_code';
    },    
    phonePrefixes() {
      return this.$globalFields?.PhonePrefixes || [];
    },
    selectedPrefix() {
      return this.selectedIndex != null
        ? this.phonePrefixes[this.selectedIndex]?.prefix
        : null;
    },
    currentIndexNormalized() {
      const negativeIndexNormalizedToPositive =
        this.phonePrefixes.length -
        1 -
        ((this.currentIndex * -1 - 1) % this.phonePrefixes.length);
      const overflowedIndexNormalized = this.currentIndex % this.phonePrefixes.length;
      return this.currentIndex < 0
        ? negativeIndexNormalizedToPositive
        : overflowedIndexNormalized;
    },
    scrollPosition() {
      const elementHeight = 44;
      const elementPosition = (this.currentIndexNormalized + 1) * elementHeight;
      return elementPosition < this.maxHeight
        ? 0
        : elementPosition - this.maxHeight + elementHeight;
    },
    phoneNumber() {
      const phoneNumberWithoutPrefix = this.value
        ? this.value.substring(this.value.indexOf(" ") + 1)
        : "";
      return phoneNumberWithoutPrefix.includes("+") ? "" : phoneNumberWithoutPrefix;
    },
    fullPhoneNumber() {
      return `+${this.selectedPrefix} ${this.phoneNumber}`;
    },
  },
  mounted() {
    window.addEventListener("click", this.onWindowClick);
    this.selectedIndex = this.getInitialCountryPrefix();
  },
  beforeUnmount() {
    window.removeEventListener("click", this.onWindowClick);
  },
  methods: {
    getInitialCountryPrefix() {
      if (!this.value)
        return this.phonePrefixes.findIndex((p) => p.country === this.selectedCountry);
      if (this.value && this.value.indexOf("+") === -1)
        return this.phonePrefixes.findIndex((p) => p.country === this.selectedCountry);

      const perfectMatch = this.phonePrefixes.findIndex(
        (p) =>
          p.prefix === this.value.split(" ")[0].replace("+", "") &&
          p.Country === this.selectedCountry
      );
      if (perfectMatch > 0) return perfectMatch;

      return this.phonePrefixes.findIndex(
        (p) => p.prefix === this.value.split(" ")[0].replace("+", "")
      );
    },
    getFlagStyle(index) {
      const countryCode = (
        this.phonePrefixes &&
        (this.phonePrefixes[index] || this.phonePrefixes[0])
      )?.country?.toLowerCase();
      if (countryCode) {
        return {
          backgroundImage: `url(' ${require(`@/static/flags/${countryCode}.svg`)}')`,
        };
      }
      return "";
    },
    isCurrent(index) {
      return this.currentIndexNormalized === index;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;

      if (!this.showDropdown) {
        this.currentIndex = null;
      }
    },
    selectCountry(index) {
      this.selectedIndex = index;
      this.$emit("input", this.fullPhoneNumber);
      this.toggleDropdown();
    },
    onCountryHover(index) {
      this.currentIndex = index;
    },
    onInput(value) {
      this.$emit("input", `+${this.selectedPrefix} ${value}`);
    },
    onKeyDown(e) {
      if (!e.key) return;

      switch (e.key.toLowerCase()) {
        case "arrowdown":
          if (!this.showDropdown) this.toggleDropdown();
          if (this.currentIndex === null) this.currentIndex = -1;

          e.preventDefault();
          this.currentIndex++;

          if (this.$refs.dropdown) {
            this.$refs.dropdown.scrollTo(0, this.scrollPosition);
          }
          break;
        case "arrowup":
          if (!this.showDropdown) this.toggleDropdown();
          if (this.currentIndex === null) this.currentIndex = 0;

          e.preventDefault();
          this.currentIndex--;

          if (this.$refs.dropdown) {
            this.$refs.dropdown.scrollTo(0, this.scrollPosition);
          }
          break;
        case "enter":
          if (this.currentIndex !== null) {
            this.selectedIndex = this.currentIndex;
            this.toggleDropdown();
            this.$emit("input", this.fullPhoneNumber);
          }
          break;
        case "escape":
          if (this.showDropdown) {
            this.toggleDropdown();
          }
          break;
        case "shift":
        case "tab":
          break;
        default:
          if (!this.showDropdown) this.toggleDropdown();

          this.$nextTick(() => {
            const matchingCountries = this.phonePrefixes
              .map((p, i) => ({ country: p.country, index: i }))
              .filter((p) => p.country.toLowerCase().startsWith(e.key.toLowerCase()))
              .map((p) => p.index);

            if (matchingCountries.length > 0) {
              const resetIndex =
                this.currentIndex === matchingCountries[matchingCountries.length - 1] ||
                this.currentIndex === null ||
                !this.phonePrefixes[this.currentIndex].country
                  .toLowerCase()
                  .startsWith(e.key.toLowerCase());

              this.currentIndex = resetIndex
                ? matchingCountries[0]
                : matchingCountries.find((c) => c > this.currentIndex);
              this.$refs.dropdown.scrollTo(0, this.scrollPosition);
            }
          });
          break;
      }
    },
    onWindowClick(e) {
      if (
        this.showDropdown &&
        !this.$refs.selector.contains(e.target) &&
        !this.$refs.dropdown.contains(e.target)
      ) {
        this.toggleDropdown();
      }
    },
  },
};
</script>

<template>
  <div class="phone-input" data-cy="phone-input">
    <div class="phone-input__main">
      <div
        tabindex="0"
        class="phone-input__selector"
        @click="toggleDropdown()"
        @keydown="onKeyDown"
        ref="selector"
        :aria-label="countryCodeText"
        @focus="$emit('focus')"
      >
        <div
          v-if="selectedIndex != null"
          :style="getFlagStyle(selectedIndex)"
          class="phone-input__flag"
        ></div>
        <span class="phone-input__text">+{{ selectedPrefix }}</span>
        <dropdown-arrow class="phone-input__dropdown" />
      </div>
      <text-input
        :id="id"
        :name="id"      
        autocomplete="tel-national"
        type="text"
        class="phone-input__text-input"
        :model-value="phoneNumber"
        :placeholder="placeholder"
        :errorMessage="errorMessage"
        @keydown="$emit('keydown', $event)"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @input="onInput"
        @enter-key-pressed="$emit('enter-key-pressed')"
      />
    </div>
    <ul
      v-if="showDropdown"
      class="phone-input__dropdown-items"
      :style="{ maxHeight: maxHeight + 'px' }"
      ref="dropdown"
    >
      <li
        v-for="(item, index) in phonePrefixes"
        :key="item.country"
        class="phone-input__dropdown-item"
        :class="{ 'phone-input__dropdown-item--selected': isCurrent(index) }"
        @mousemove="onCountryHover(index)"
        @click="selectCountry(index)"
      >
        <div :style="getFlagStyle(index)" class="phone-input__flag"></div>
        <span class="phone-input__dropdown-item-text"
          >{{ item.country }} (+{{ item.prefix }})</span
        >
      </li>
    </ul>
  </div>
</template>

<style>
.phone-input {
  width: 100%;
  position: relative;
}

.phone-input__main {
  display: flex;
}

.phone-input__selector {
  flex: 0.2;
  min-width: 100px;
  height: 41px;
  background-color: var(--color-text-white);
  border: 1px solid var(--color-neutral-50);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  cursor: pointer;
}

.phone-input__text-input {
  flex: 1;
  margin-left: 0.75rem;
}

.phone-input__dropdown-items {
  list-style-type: none;
  overflow: auto;
  width: 100%;
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  margin: 0;
  background-color: var(--color-text-white);
  border: 1px solid var(--color-neutral-50);
  border-radius: 3px;
  z-index: 1;
  padding: 0;
}

.phone-input__dropdown-item {
  display: flex;
  padding: 11px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--color-neutral-03);
}

.phone-input__dropdown-item:last-child {

  border-bottom: none;
}

.phone-input__dropdown-item-text {
  margin-left: 1rem;
}

.phone-input__dropdown-item--selected,
.phone-input__dropdown-item:hover {
  background-color: var(--color-neutral-05);
}

.phone-input__flag {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
}

.phone-input__text {
  font-size: var(--size-xs);
}
</style>
