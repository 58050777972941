import { defineReceiver, defineCommand } from '@drapejs/invoker';
import { gql } from 'graphql-request';
import { request, query, sales, batch } from '@distancify/drapejs-litium';
import { fetchPage } from '@drapejs/core';
import { isNode } from '@drapejs/runtime-context';
import { ERRORS } from '@/composables/constants';

export const userFieldsStr = `
  isAuthenticated
  companyName
  reviewerId
  organization {
      organizationName
      organizationNo
      organizationId
      name
      address
      address2
      postCode
      city
      country
      countries {
        value
        text
      }
      installerData {
        phone
        email
        website
        systemId
        counties
      }
      shippingAddresses {
        no
        code
        systemId
        name
        address
        address2
        postCode
        city
        country
      }
      contacts {
        no
        code
        systemId
        firstName
        lastName
        email
        phone
      }
  }
  organizations {
      organizationName
      organizationId
  }
  audiences {
    id
    name
    isSubscribed
  }
  person {
      id
      firstName
      lastName
      email
      emailUnverified
      phone
      title
      bio
      numberOfReviews
      profileImageId
  }`;

export const userFields = gql`
fragment UserFields on User {
  ${userFieldsStr}
}
`;

export const commands = {
  changeOrganization: defineCommand<{
    url: string;
    organizationId: string;
  }>('changeOrganization'),
  login: defineCommand<{
    username: string;
    password: string;
  }>('login'),
  logout: defineCommand<{}>('logout'),
  forgotPassword: defineCommand<{
    email: string;
  }>('forgotPassword'),
  validateResetPasswordCode: defineCommand<{
    resetPasswordCode: string;
  }>('validateResetPasswordCode'),
  resetPassword: defineCommand<{
    resetPasswordCode: string;
    password: string;
  }>('resetPassword'),
  fetchMyOrders: defineCommand<{
    url: string;
  }>('fetchMyOrders'),
  fetchMyOrderDetails: defineCommand<{
    url: string;
    orderNo: string;
  }>('fetchMyOrderDetails'),
  fetchMyInvoiceDetails: defineCommand<{
    url: string;
    invoiceNo: string;
  }>('fetchMyInvoiceDetails'),
  updatePerson: defineCommand<{
    url: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    title: string;
    bio: string;
  }>('updatePerson'),
  sendEmailVerification: defineCommand<{
    url: string;
    newEmail: string;
  }>('sendEmailVerification'),
  changePassword: defineCommand<{
    url: string;
    oldPassword: string;
    newPassword: string;
  }>('changePassword'),
  changeChannel: defineCommand<{
    url: string;
    countryId: string;
    pageId: string;
  }>('changeChannel'),
  updateBillingAddress: defineCommand<{
    url: string;
    no: string;
    name: string;
    address: string;
    address2: string;
    postCode: string;
    city: string;
    country: string;
  }>('updateBillingAddress'),
  addOrUpdateShippingAddress: defineCommand<{
    url: string;
    no: string;
    code: string;
    systemId: string;
    name: string;
    address: string;
    address2: string;
    postCode: string;
    city: string;
    country: string;
    saveOnOrganization: boolean;
    syncDelivery: boolean;
  }>('addOrUpdateShippingAddress'),
  deleteShippingAddress: defineCommand<{
    url: string;
    no: string;
    code: string;
    systemId: string;
  }>('deleteShippingAddress'),
  addOrUpdateContact: defineCommand<{
    url: string;
    no: string;
    code: string;
    systemId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    saveOnOrganization: boolean;
    syncContact: boolean;
  }>('addOrUpdateContact'),
  subscribeToAudience: defineCommand<{
    url: string;
    email: string;
    audience: string;
  }>('subscribeToAudience'),
  unsubscribeFromAudience: defineCommand<{
    url: string;
    email: string;
    audience: string;
  }>('unsubscribeFromAudience'),
  register: defineCommand<{
    url: string;
    organizationNumber: string;
  }>('register'),
  setInitialPerson: defineCommand<{
    url: string;
    organizationId: string;
    occupation: string;
    name: string;
    email: string;
    phone: string;
  }>('setInitialPerson'),
  refreshUser: defineCommand<{
    url: string;
  }>('refreshUser'),
  setShippingAddress: defineCommand<{
    url: string;
    addressId: string;
  }>('setShippingAddress'),
  fetchInstallers: defineCommand<{
    url: string;
    skip: number;
    take: number;
    counties: string[];
  }>("fetchInstallers"),
  addOrUpdateInstaller: defineCommand<{
    url: string;
    inputData: {
      email: string;
      phone: string;
      website: string;
      counties: string[]
    }
  }>("addOrUpdateInstaller"),
  removeInstaller: defineCommand<{
    url: string;
    installerId: string;
  }>("removeInstaller"),
  validateEmail: defineCommand<{
    url: string;
    verificationCode: string;
  }>("validateEmail"),
};

export const receivers = {
  changeOrganization: defineReceiver(
    commands.changeOrganization,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation changeOrganization($url: String!, $organizationId: Guid!) {
              session(url: $url) {
                changeOrganization(organizationId: $organizationId) {
                  error
                  cart {
                    ...CartFields
                  }
                  user {
                    ...UserFields
                  }
                }
              }
            }
            ${userFields}
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          organizationId: command.organizationId,
        }
      );
 
      const { error, user, cart } = result.session.changeOrganization;

      if (error == "NONE" || error == ERRORS.NOT_AUTHENTICATED) {
        await this.cache.setItem("__user", user);
        await this.cache.setItem("__cart", cart);
      }        

      return result.session.changeOrganization;
    },
    'litium'
  ),
  login: defineReceiver(
    commands.login,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation login(
              $url: String!
              $username: String!
              $password: String!
            ) {
              session(url: $url) {
                login(username: $username, password: $password) {
                  error
                  cart {
                    ...CartFields
                  }
                  user {
                    ...UserFields
                  }
                }
              }
            }
            ${userFields}
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          username: command.username,
          password: command.password,
        }
      );

      const { error, user, cart } = result.session.login;

      if (error === 'NONE') {
        await this.cache.setItem('__cart', cart);
      }

      await this.cache.setItem('__user', user);

      return result.session.login;
    },
    'litium'
  ),
  logout: defineReceiver(
    commands.logout,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation logout($url: String!) {
              session(url: $url) {
                logout {
                  error
                  cart {
                    ...CartFields
                  }
                  user {
                    ...UserFields
                  }
                }
              }
            }
            ${userFields}
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
        }
      );

      const { error, user, cart } = result.session.logout;

      if (error === 'NONE') {
        await this.cache.setItem('__cart', cart);
      }

      await this.cache.setItem('__user', user);

      return result.session.logout;
    },
    'litium'
  ),
  forgotPassword: defineReceiver(
    commands.forgotPassword,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation forgotPassword($url: String!, $email: String!) {
            session(url: $url) {
              forgot(email: $email) {
                error
              }
            }
          }
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          email: command.email,
        }
      );

      return result.session.forgot;
    },
    'litium'
  ),
  validateResetPasswordCode: defineReceiver(
    commands.validateResetPasswordCode,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation resetPassword($url: String!, $resetPasswordCode: String!) {
            session(url: $url) {
              validateResetPasswordCode(resetPasswordCode: $resetPasswordCode)
            }
          }
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          resetPasswordCode: command.resetPasswordCode,
        }
      );

      return {
        isValid: result.session.validateResetPasswordCode,
      };
    },
    'litium'
  ),
  resetPassword: defineReceiver(
    commands.resetPassword,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation resetPassword(
            $url: String!
            $password: String!
            $resetPasswordCode: String!
          ) {
            session(url: $url) {
              reset(
                password: $password
                resetPasswordCode: $resetPasswordCode
              ) {
                error
                user {
                  ...UserFields
                }
              }
            }
          }
          ${userFields}
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          password: command.password,
          resetPasswordCode: command.resetPasswordCode,
        }
      );

      await this.cache.setItem('__user', result.session.reset.user);

      return result.session.reset;
    },
    'litium'
  ),
  fetchMyOrders: defineReceiver(
    commands.fetchMyOrders,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation fetchMyOrders($url: String!) {
            session(url: $url) {
              fetchMyOrders {
                number
                date
                formattedAmount
                isInvoice
              }
            }
          }
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
        }
      );
      return result.session.fetchMyOrders;
    },
    'litium'
  ),
  fetchMyOrderDetails: defineReceiver(
    commands.fetchMyOrderDetails,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation fetchMyOrderDetails($url: String!, $orderNo: String) {
            session(url: $url) {
              fetchMyOrderDetails(orderNo: $orderNo) {
                unauthorized
                no
                date
                currency
                customer {
                  name
                  name2
                  address
                  address2
                  postCode
                  city
                  country
                }
                shipping {
                  date
                  agent
                  name
                  name2
                  address
                  address2
                  postCode
                  city
                  country
                  contactName
                  contactPhone
                  contactEmail
                }
                rows {
                  lineNumber
                  sku
                  imageSystemId
                  description
                  description2
                  quantity
                  price
                  vat
                }
                price
                vat
              }
            }
          }
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          orderNo: command.orderNo,
        }
      );
      return result.session.fetchMyOrderDetails;
    },
    'litium'
  ),
  fetchMyInvoiceDetails: defineReceiver(
    commands.fetchMyInvoiceDetails,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation fetchMyInvoiceDetails($url: String!, $invoiceNo: String) {
            session(url: $url) {
              fetchMyInvoiceDetails(invoiceNo: $invoiceNo) {
                unauthorized
                no
                date
                currency
                customer {
                  name
                  name2
                  address
                  address2
                  postCode
                  city
                  country
                }
                shipping {
                  date
                  agent
                  name
                  name2
                  address
                  address2
                  postCode
                  city
                  country
                  contactName
                  contactPhone
                  contactEmail
                }
                rows {
                  lineNumber
                  sku
                  imageSystemId
                  description
                  description2
                  quantity
                  price
                  vat
                }
                price
                vat
              }
            }
          }
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          invoiceNo: command.invoiceNo,
        }
      );
      return result.session.fetchMyInvoiceDetails;
    },
    'litium'
  ),
  updatePerson: defineReceiver(
    commands.updatePerson,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation updatePerson(
            $url: String!
            $firstName: String!
            $lastName: String!
            $phoneNumber: String!
            $title: String
            $bio: String
          ) {
            session(url: $url) {
              updatePerson(
                firstName: $firstName
                lastName: $lastName
                phoneNumber: $phoneNumber
                title: $title
                bio: $bio
              ) {
                error
                user {
                  ...UserFields
                }
              }
            }
          }
          ${userFields}
        `),
        {
          url: command.url,
          firstName: command.firstName,
          lastName: command.lastName,
          phoneNumber: command.phoneNumber,
          title: command.title,
          bio: command.bio,
        }
      );

      await this.cache.setItem('__user', result.session.updatePerson.user);

      return result.session.updatePerson;
    },
    'litium'
  ),
  sendEmailVerification: defineReceiver(
    commands.sendEmailVerification,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation sendEmailVerification($url: String!, $newEmail: String!) {
            session(url: $url) {
              sendEmailVerification(newEmail: $newEmail) {
                error
                user {
                  ...UserFields
                }
              }
            }
          }
          ${userFields}
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          newEmail: command.newEmail,
        }
      );

      await this.cache.setItem(
        '__user',
        result.session.sendEmailVerification.user
      );

      return result.session.sendEmailVerification;
    },
    'litium'
  ),
  changePassword: defineReceiver(
    commands.changePassword,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation changePassword(
            $url: String!
            $oldPassword: String!
            $newPassword: String!
          ) {
            session(url: $url) {
              changePassword(
                oldPassword: $oldPassword
                newPassword: $newPassword
              )
            }
          }
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          oldPassword: command.oldPassword,
          newPassword: command.newPassword,
        }
      );

      return { error: result.session.changePassword };
    },
    'litium'
  ),
  changeChannel: defineReceiver(
    commands.changeChannel,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation setCountry(
            $url: String!
            $countryId: String!
            $pageId: String!
          ) {
            session(url: $url) {
              setCountry(countryId: $countryId, pageId: $pageId) {
                error
                redirectUrl
              }
            }
          }
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          countryId: command.countryId,
          pageId: command.pageId || '',
        }
      );

      const { error } = result.session.setCountry;
      switch (error) {
        case 'NONE':
          return result.session.setCountry;
        case 'FAILED':
        default:
          throw 'Unknown error occurred';
      }
    },
    'litium'
  ),
  updateBillingAddress: defineReceiver(
    commands.updateBillingAddress,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation updateBillingAddress(
            $url: String!
            $no: String!
            $name: String!
            $address: String!
            $address2: String!
            $postCode: String!
            $city: String!
            $country: String!
          ) {
            session(url: $url) {
              updateBillingAddress(
                no: $no
                name: $name
                address: $address
                address2: $address2
                postCode: $postCode
                city: $city
                country: $country
              ) {
                error
                user {
                  ...UserFields
                }
              }
            }
          }
          ${userFields}
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          no: command.no,
          name: command.name,
          address: command.address,
          address2: command.address2,
          postCode: command.postCode,
          city: command.city,
          country: command.country,
        }
      );

      const { error, user } = result.session.updateBillingAddress;

      if (error == 'NONE' || error == ERRORS.NOT_AUTHENTICATED) {
        await this.cache.setItem('__user', user);
      }

      return result.session.updateBillingAddress;
    },
    'litium'
  ),
  addOrUpdateShippingAddress: defineReceiver(
    commands.addOrUpdateShippingAddress,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation addOrUpdateShippingAddress(
              $url: String!
              $no: String!
              $code: String!
              $systemId: String!
              $name: String!
              $address: String!
              $address2: String!
              $postCode: String!
              $city: String!
              $country: String!
              $saveOnOrganization: Boolean
              $syncDelivery: Boolean
            ) {
              session(url: $url) {
                addOrUpdateShippingAddress(
                  no: $no
                  code: $code
                  systemId: $systemId
                  name: $name
                  address: $address
                  address2: $address2
                  postCode: $postCode
                  city: $city
                  country: $country
                  saveOnOrganization: $saveOnOrganization
                  syncDelivery: $syncDelivery
                ) {
                  error
                  cart {
                    ...CartFields
                  }
                  user {
                    ...UserFields
                  }
                }
              }
            }
            ${userFields}
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          no: command.no || '',
          code: command.code || '',
          systemId: command.systemId || '',
          name: command.name || '',
          address: command.address || '',
          address2: command.address2 || '',
          postCode: command.postCode || '',
          city: command.city || '',
          country: command.country || '',
          saveOnOrganization: command.saveOnOrganization || false,
          syncDelivery: command.syncDelivery || false,
        }
      );

      const { error, cart, user } = result.session.addOrUpdateShippingAddress;

      if (error === 'NONE' || error === ERRORS.NOT_AUTHENTICATED) {
        await this.cache.setItem('__cart', cart);
        await this.cache.setItem('__user', user);
      }

      return result.session.addOrUpdateShippingAddress;
    },
    'litium'
  ),
  deleteShippingAddress: defineReceiver(
    commands.deleteShippingAddress,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation deleteShippingAddress(
              $url: String!
              $no: String!
              $code: String!
              $systemId: Guid!
            ) {
              session(url: $url) {
                deleteShippingAddress(
                  no: $no
                  code: $code
                  systemId: $systemId
                ) {
                  error
                  cart {
                    ...CartFields
                  }
                  user {
                    ...UserFields
                  }
                }
              }
            }
            ${userFields}
          `,
          ...sales.withCartFields()
        ),
        {
          url: command.url,
          no: command.no,
          code: command.code,
          systemId: command.systemId,
        }
      );

      const { user, cart, error } = result.session.deleteShippingAddress;

      if (error == 'NONE' || error == ERRORS.NOT_AUTHENTICATED) {
        await this.cache.setItem('__user', user);
        await this.cache.setItem('__cart', cart);
      }

      return result.session.deleteShippingAddress;
    },
    'litium'
  ),
  addOrUpdateContact: defineReceiver(
    commands.addOrUpdateContact,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation addOrUpdateContact(
              $url: String!
              $no: String!
              $code: String!
              $systemId: String!
              $firstName: String!
              $lastName: String!
              $email: String!
              $phone: String!
              $saveOnOrganization: Boolean
              $syncContact: Boolean
            ) {
              session(url: $url) {
                addOrUpdateContact(
                  no: $no
                  code: $code
                  systemId: $systemId
                  firstName: $firstName
                  lastName: $lastName
                  email: $email
                  phone: $phone
                  saveOnOrganization: $saveOnOrganization
                  syncContact: $syncContact
                ) {
                  error
                  cart {
                    ...CartFields
                  }
                  user {
                    ...UserFields
                  }
                }
              }
            }
            ${userFields}
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          no: command.no || '',
          code: command.code || '',
          systemId: command.systemId || '',
          firstName: command.firstName || '',
          lastName: command.lastName || '',
          email: command.email || '',
          phone: command.phone || '',
          saveOnOrganization: command.saveOnOrganization || false,
          syncContact: command.syncContact || false,
        }
      );

      const { error, cart, user } = result.session.addOrUpdateContact;

      if (error === 'NONE' || error === ERRORS.NOT_AUTHENTICATED) {
        await this.cache.setItem('__cart', cart);
        await this.cache.setItem('__user', user);
      }

      return result.session.addOrUpdateContact;
    },
    'litium'
  ),
  subscribeToAudience: defineReceiver(
    commands.subscribeToAudience,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation subscribeToAudience(
            $url: String!
            $email: String!
            $audience: String!
          ) {
            session(url: $url) {
              subscribeToAudience(email: $email, audience: $audience) {
                error
                user {
                  ...UserFields
                }
              }
            }
          }
          ${userFields}
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          email: command.email,
          audience: command.audience,
        }
      );

      if (result.session.subscribeToAudience.error == 'NONE') {
        await this.cache.setItem(
          '__user',
          result.session.subscribeToAudience.user
        );
      }

      return result.session.subscribeToAudience;
    },
    'litium'
  ),
  unsubscribeFromAudience: defineReceiver(
    commands.unsubscribeFromAudience,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation unsubscribeFromAudience(
            $url: String!
            $email: String!
            $audience: String!
          ) {
            session(url: $url) {
              unsubscribeFromAudience(email: $email, audience: $audience) {
                error
                user {
                  ...UserFields
                }
              }
            }
          }
          ${userFields}
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          email: command.email,
          audience: command.audience,
        }
      );

      if (result.session.unsubscribeFromAudience.error == 'NONE') {
        await this.cache.setItem(
          '__user',
          result.session.unsubscribeFromAudience.user
        );
      }

      return result.session.unsubscribeFromAudience;
    },
    'litium'
  ),
  register: defineReceiver(
    commands.register,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation register($url: String!, $organizationNumber: String!) {
              session(url: $url) {
                register(organizationNumber: $organizationNumber) {
                  error
                  organizationId
                  organizationSystemId
                  organizationNumber
                  organizationName
                  organizationAddress
                }
              }
            }
          `
        ),
        {
          url: command.url,
          organizationNumber: command.organizationNumber,
        }
      );

      return result.session.register;
    },
    'litium'
  ),
  setInitialPerson: defineReceiver(
    commands.setInitialPerson,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation setInitialPerson(
              $url: String!
              $organizationId: Guid!
              $occupation: String!
              $name: String!
              $email: String!
              $phone: String!
            ) {
              session(url: $url) {
                setInitialPerson(
                  organizationId: $organizationId
                  occupation: $occupation
                  name: $name
                  email: $email
                  phone: $phone
                ) {
                  error
                }
              }
            }
          `
        ),
        {
          url: command.url,
          organizationId: command.organizationId,
          occupation: command.occupation,
          name: command.name,
          email: command.email,
          phone: command.phone,
        }
      );

      return result.session.setInitialPerson;
    },
    'litium'
  ),
  refreshUser: defineReceiver(
    commands.refreshUser,
    async function (command, data) {
      try {
        const result = await request(
          this.cache,
          query(
            gql`
              query refreshUser($url: String!) {
                session(url: $url) {
                  user {
                    ...UserFields
                  }
                }
              }
              ${userFields}
            `
          ),
          {
            url: command.url,
          }
        );

        if (result.session.user) {
          await this.cache.setItem('__user', result.session.user);
          return result.session.user;
        } else {
          await this.cache.setItem('__user', { isAuthenticated: false });
          return { isAuthenticated: false };
        }
      } catch (err) {
        console.error(err);
        await this.cache.setItem('__user', { isAuthenticated: false });
        return { isAuthenticated: false };
      }
    },
    'litiumUser'
  ),
  setShippingAddress: defineReceiver(
    commands.setShippingAddress,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation setShippingAddress($url: String!, $addressId: String!) {
              session(url: $url) {
                setShippingAddress(addressId: $addressId) {
                  error
                  cart {
                    ...CartFields
                  }
                  user {
                    ...UserFields
                  }
                }
              }
            }
            ${userFields}
          `,
          ...sales.withCartFields()
        ),
        {
          url: command.url,
          addressId: command.addressId,
        }
      );

      const { error, cart, user } = result.session.setShippingAddress;

      if (error === 'NONE' || error === ERRORS.NOT_AUTHENTICATED) {
        await this.cache.setItem('__cart', cart);
        await this.cache.setItem('__user', user);
      }

      return result.session.setShippingAddress;
    },
    'litium'
  ),
  fetchInstallers: defineReceiver(
    commands.fetchInstallers,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            query installerSearch(
              $url: String!
              $skip: Int!
              $take: Int!
              $counties: [String]!
            ) {
              session(url: $url) {
                installerSearch(skip: $skip, take: $take, counties: $counties) {
                  hits
                  installers {
                    installerSystemId
                    phone
                    email
                    website
                    organizationName
                  }
                }
              }
            }
          `,
        ),
        {
          url: command.url,
          skip: command.skip || 0,
          take: command.take || 0,
          counties: command.counties || []
        },
      );

      return result.session.installerSearch;
    },
    "litium"
  ),
  addOrUpdateInstaller: defineReceiver(
    commands.addOrUpdateInstaller,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation addOrUpdateInstaller(
              $url: String!
              $inputData: AddOrUpdateInstallerInput!
            ) {
              session(url: $url) {
                addOrUpdateInstaller(inputData: $inputData) {
                  error
                  user {
                    ...UserFields
                  }
                }
              }
            }
            ${userFields}
          `,
        ),
        {
          url: command.url,
          inputData: command.inputData
        },
      );

      const { error, user } = result.session.addOrUpdateInstaller;

      if (error == "NONE" || error == ERRORS.NOT_AUTHENTICATED) {
        await this.cache.setItem("__user", user);
      }

      return result.session.addOrUpdateInstaller;
    },
    "litium"
  ),
  removeInstaller: defineReceiver(
    commands.removeInstaller,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation removeInstaller(
              $url: String!
              $installerId: String!
            ) {
              session(url: $url) {
                removeInstaller(installerId: $installerId) {
                  error
                  user {
                    ...UserFields
                  }
                }
              }
            }
            ${userFields}
          `,
        ),
        {
          url: command.url,
          installerId: command.installerId
        },
      );

      const { error, user } = result.session.removeInstaller;

      if (error == "NONE" || error == ERRORS.NOT_AUTHENTICATED) {
        await this.cache.setItem("__user", user);
      }

      return result.session.removeInstaller;
    },
    "litium"
  ),
  validateEmail: defineReceiver(
    commands.validateEmail,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation validateEmail($url: String!, $verificationCode: String!) {
              session(url: $url) {
                validateEmail(verificationCode: $verificationCode) {
                  error
                  cart {
                    ...CartFields
                  }
                  user {
                    ...UserFields
                  }
                }
              }
            }
            ${userFields}
          `,
          ...sales.withCartFields()
        ),
        {
          url: command.url,
          verificationCode: command.verificationCode,
        }
      );

      const { error, cart, user } = result.session.validateEmail;

      if (error === 'NONE' || error === ERRORS.NOT_AUTHENTICATED) {
        await this.cache.setItem('__cart', cart);
        await this.cache.setItem('__user', user);
      }

      return result.session.validateEmail;
    },
    'litium'
  ),
};

export const scheduleRefreshUserReceiver = defineReceiver(
  fetchPage,
  async function (command: any, data) {
    if (isNode()) {
      return data;
    }

    await this.invoke(batch.scheduleQuery, {
      cacheKey: '__user',
      query: gql`
        user {
          ...UserFields
        }`,
      fragments: [userFields],
    });

    return data;
  }
);
