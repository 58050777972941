<script>
export default {
  props: {
    expanded: {
      type: Boolean,
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    direction() {
      return this.expanded ? 'up' : 'down';
    },
    rotation() {
      switch (this.direction) {
        case 'right':
          return -90;
        case 'left':
          return 90;
        case 'up':
          return -180;
        case 'down':
          return 0;
      }
    },
    style() {
      return {
        transform: `rotate(${this.rotation}deg)`,
      };
    },
    pathStyle() {
      if (this.color) {
        return {
          fill: this.color,
        };
      }
      return {};
    },
  },
};
</script>

<template>
  <span class="arrow-expandable">
    <svg
      :style="style"
      class="arrow"
      viewBox="0 0 48 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path
          class="arrow__path"
          :style="pathStyle"
          d="M18.1002686,24.5 L34.3270399,7.98697064 C35.22432,7.07619685 35.22432,5.59560562 34.3270399,4.68483183 C33.4320547,3.77172272 31.9794247,3.77172272 31.0821446,4.68483183 L13.9810415,22.0876171 C12.6729862,23.4210834 12.6729862,25.5789166 13.9810415,26.9123829 L31.0821446,44.3151682 C31.9794247,45.2282773 33.4320547,45.2282773 34.3270399,44.3151682 C35.22432,43.4043944 35.22432,41.9238031 34.3270399,41.0130294 L18.1002686,24.5 Z"
          transform="translate(24.000000, 24.500000) rotate(-90.000000) translate(-24.000000, -24.500000) "
        ></path>
      </g>
    </svg>
  </span>
</template>

<style>
.arrow-expandable {
  height: 20px;
  width: 20px;
  margin-left: 0.5rem;
  display: inline-block;
}

.arrow {
  width: inherit;
  height: inherit;
}

.arrow__path {
  fill: var(--color-neutral-50);
}
</style>
