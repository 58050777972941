import { inject, ref, computed } from 'vue';
import { invokeKey } from '@drapejs/core';
import * as userConnector from '@/connectors/user';

import { getUserProviderContext } from './useUserProvider';

export default function () {
  const invoke: any = inject(invokeKey);
  const requestPending = ref(false);

  const { user, setUser } = getUserProviderContext();

  const pendingAuth = computed(() => {
    return user.value.pendingAuth || false;
  });

  async function register(organizationNumber: string) {
    try {
      requestPending.value = true;

      const res = await invoke(userConnector.commands.register, {
        url: window.location.href,
        organizationNumber,
      });
      return res;
    } finally {
      requestPending.value = false;
    }
  }

  async function setInitialPerson(
    organizationId: string,
    occupation: string,
    name: string,
    email: string,
    phone: string
  ) {
    try {
      requestPending.value = true;

      const res = await invoke(userConnector.commands.setInitialPerson, {
        url: window.location.href,
        organizationId,
        occupation,
        name,
        email,
        phone,
      });
      return res;
    } finally {
      requestPending.value = false;
    }
  }

  async function refreshUser() {
    try {
      requestPending.value = true;
      const response = await invoke(userConnector.commands.refreshUser, {
        url: window.location.href,
      });
      setUser(response);
      return response;
    } catch (err) {
      setUser({ isAuthenticated: false });
      console.error(err);
      return {
        isAuthenticated: false,
      };
    } finally {
      requestPending.value == false;
    }
  }

  return {
    register,
    setInitialPerson,
    refreshUser,
    requestPending,
    user,
    pendingAuth,
  };
}
