import { InjectionKey, Ref } from 'vue';
import { DataSourceEntries, Link } from '@distancify/drapejs-storyblok';
import { Emitter } from 'mitt';

interface CartData {
  isEmpty: boolean;
}

interface Cart {
  data: CartData;
}

interface MetaProduct {}

interface MenuItem {
  _uid: string;
  label: string;
  link: Link;
}

export interface Settings {
  favicon: object;
  tagline: string;
  usp: string;
  mainMenu: MenuItem[];
}

export interface User {
  isAuthenticated: Boolean;
  pendingAuth?: Boolean;
  organizationMame?: string;
  person?: any
  organization?: any;
  companyName?: string;
  reviewerId?: string;
  organizations?: any[];
}

export const textsKey: InjectionKey<Ref<DataSourceEntries>> = Symbol('texts');
export const cartKey: InjectionKey<Ref<any>> = Symbol('cart');
export const currentPageKey: InjectionKey<Ref<any>> = Symbol('currentPageKey');
export const userKey: InjectionKey<Ref<User>> = Symbol('userKey');
export const emitterKey: InjectionKey<Emitter> = Symbol('emitterKey');
export const settingsKey: InjectionKey<Ref<Settings>> = Symbol('settings');
export const metaProductKey: InjectionKey<Ref<MetaProduct>> =
  Symbol('metaProduct');
export const channelKey: InjectionKey<Ref<any>> = Symbol('channel');
export const categoryTreeKey: InjectionKey<Ref<any>> = Symbol('categoryTree');
export const isNavigationOpenKey: InjectionKey<Ref<boolean>> =
  Symbol('isNavigationOpen');
export const isSearchOpenKey: InjectionKey<Ref<boolean>> =
  Symbol('isSearchOpen');
export const replaceTokensKey: InjectionKey<
  (text: string, args?: { [key: string]: string }) => string
> = Symbol('replaceTokens');
