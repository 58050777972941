import { ref, computed } from 'vue';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import * as bellaliteUser from '@/connectors/user';

import useUser from './useUser';
import useContext from './useContext';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import { ERRORS } from '@/composables/constants';
import useExecuteCommand from './useExecuteCommand';

export default function () {
  const { user } = useUser();
  const { cart, channel, navigateToLoginPage } = useContext();
  const { websiteText } = useWebsiteTexts();
  const { executeCommand } = useExecuteCommand();

  const requestPending = ref(false);

  const installerData = ref<{
    phone: string;
    phoneFormatted: string;
    phoneError: string;
    email: string;
    emailError: string;
    website: string;
    websiteError: string;
    counties: string[];
    countiesError: string;
    systemId: string;
    submitError: string;
  }>({
    phone: '',
    phoneFormatted: user.value?.organization?.installerData?.phone || '',
    phoneError: '',
    email: '',
    emailError: '',
    website: '',
    websiteError: '',
    submitError: '',
    counties: [],
    countiesError: '',
    ...(user.value?.organization?.installerData || {}),
  });
  const canEditCounties = ref(!user.value?.organization?.installerData);

  const hasInstallerData = computed(() => {
    return user.value?.organization?.installerData;
  });

  const phoneCountry = computed(() => {
    if (installerData.value.phone) {
      const prefix = installerData.value.phone.split(' ')[0].replace('+', '');
      const matchingPrefix = (
        channel.value?.website?.websiteFields?.PhonePrefixes || []
      ).find((p: any) => p.prefix === prefix);

      if (matchingPrefix) {
        return matchingPrefix.country;
      }
    }
    return cart.value.countryId;
  });

  const counties = computed(() => {
    return channel.value?.counties || [];
  });

  function checkIfDataChanged() {
    if (!hasInstallerData.value) return true;

    const organization = user.value?.organization?.installerData;
    const installer = installerData.value;

    if (
      installer.phoneFormatted !== organization.phone ||
      installer.email !== organization.email ||
      installer.website !== organization.website ||
      installer.counties.length !== organization.counties.length ||
      !installer.counties.every(
        (val, index) => val === organization.counties[index]
      )
    ) {
      return true;
    }

    return false;
  }

  function getCountiesText() {
    return installerData.value.counties.map((countyValue) => {
      const matchingCounty = counties.value.filter(
        (county: { value: string; text: string }) =>
          county.value === countyValue
      )[0];
      return matchingCounty ? matchingCounty.text : null;
    });
  }

  function updatePhoneNumber(phoneNumberValue: string) {
    installerData.value.phoneError = '';
    installerData.value.phone = phoneNumberValue;

    const parsedNumber = parsePhoneNumberFromString(
      phoneNumberValue,
      phoneCountry.value
    );

    installerData.value.phoneFormatted = `+${parsedNumber?.countryCallingCode} ${parsedNumber?.nationalNumber}`;
  }

  function updateWebsite(websiteValue: string) {
    installerData.value.websiteError = '';
    installerData.value.website = websiteValue;
  }

  async function addOrUpdateInstaller() {
    try {
      requestPending.value = true;
      if (!checkIfDataChanged()) return;
      const inputData = {
        phone: installerData.value.phoneFormatted,
        email: installerData.value.email,
        website: installerData.value.website || '',
        counties: [...installerData.value.counties],
      };

      const { error } = await executeCommand(
        bellaliteUser.commands.addOrUpdateInstaller,
        {
          inputData,
        }
      );

      if (error !== 'NONE') {
        if (error === ERRORS.NOT_AUTHENTICATED) {
          navigateToLoginPage();
        }
        throw error;
      }
    } catch (err) {
      console.error(err);
      installerData.value.submitError = websiteText('global__save_error').value;
      throw websiteText('global__save_error').value;
    } finally {
      requestPending.value = false;
    }
  }

  async function removeInstaller(installerId: string) {
    try {
      requestPending.value = true;

      const { error } = await executeCommand(
        bellaliteUser.commands.removeInstaller,
        {
          installerId,
        }
      );

      if (error !== 'NONE') {
        if (error === ERRORS.NOT_AUTHENTICATED) {
          navigateToLoginPage();
        }
        throw error;
      }
    } catch (err) {
      console.error(err);
      throw websiteText('global__save_error').value;
    } finally {
      requestPending.value = false;
    }
  }

  function resetFormSubmissionState() {
    requestPending.value = false;
  }

  return {
    installerData,
    counties,
    hasInstallerData,
    requestPending,
    phoneCountry,
    canEditCounties,
    updatePhoneNumber,
    updateWebsite,
    addOrUpdateInstaller,
    removeInstaller,
    resetFormSubmissionState,
    getCountiesText,
  };
}
