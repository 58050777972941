export default function () {
  let timeoutId: number | null = null;

  const executeAction = (
    callback: Function,
    wait: number,
    ...args: Array<unknown>
  ) => {
    if (timeoutId !== null) {
      window.clearTimeout(timeoutId);
    }
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };

  const terminateAction = () => {
    if (timeoutId !== null) {
      window.clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  return { executeAction, terminateAction };
}
