import { computed, Ref } from 'vue';

import { getCustomerPriceContext } from '@/composables/useCustomerPrices';

export default function (selectedVariant: Ref<any>, quantity?: Ref<number>) {
  const { customerPrices, transationError, waitForCustomerPrices } =
    getCustomerPriceContext();

  const articleNumber = computed(() => {
    return selectedVariant.value?.id || selectedVariant.value?.articleNumber;
  });

  const minQuantity = computed(() => {
    return quantity?.value || 1;
  });

  const customerPrice = computed(() => {
    const customerPriceBrackets =
      customerPrices.value?.[articleNumber.value] || [];

    return customerPriceBrackets.find(
      (r: any) => r.quantity <= minQuantity.value
    );
  });

  const isBusy = computed(() => {
    return waitForCustomerPrices.value && !customerPrice.value?.price;
  });

  const unitPrice = computed(() => {
    const pdpVariantPrice = selectedVariant.value?.prices?.originalPrice;
    const productCardPrices =
      selectedVariant.value?.price?.originalPrice ||
      selectedVariant.value?.price?.listPrice;
    return !isBusy.value ? pdpVariantPrice || productCardPrices || 0 : '';
  });

  const campaignPrice = computed(() => {
    const pdpVariantPrice = selectedVariant.value?.prices?.campaignPrice;
    const productCardPrice = selectedVariant.value?.price?.campaignPrice;

    return !isBusy.value
      ? customerPrice.value?.price || pdpVariantPrice || productCardPrice || 0
      : '';
  });

  const fetchCustomerPriceError = computed(() => {
    return transationError.value?.[articleNumber.value];
  });

  const isCustomerPriceDifferent = computed(() => {

    if(isBusy.value) {
      return false;
    }

    const originalUnitPrice = selectedVariant.value?.prices?.originalPrice ||
                              selectedVariant.value?.price?.originalPrice ||
                              selectedVariant.value?.price?.listPrice ||
                              0;

    const originalCampaignPrice = selectedVariant.value?.prices?.campaignPrice ||
                                  selectedVariant.value?.price?.campaignPrice ||
                                  0;

    const nonCustomerPrice = originalCampaignPrice != 0 ? originalCampaignPrice : originalUnitPrice;
    const customerPrice =  campaignPrice.value != 0 ? campaignPrice.value : unitPrice.value;

    return nonCustomerPrice != customerPrice;

  });

  return {
    unitPrice,
    campaignPrice,
    customerPrice,
    fetchCustomerPriceError,
    isBusy,
    isCustomerPriceDifferent
  };
}
