<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
};
</script>

<template>
  <div class="loading-spinner">
    <div class="loading-spinner__container">
      <div class="loading-spinner__border">
        <div class="loading-spinner__inner"></div>
      </div>
    </div>
    <div class="loading-spinner__text" v-if="text">{{text}}</div>
  </div>
</template>

<style>
.loading-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-spinner__container {
  margin-bottom: 0.5rem;
}

.loading-spinner__border {
  width: 46px;
  height: 46px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63, 249, 220);
  background: linear-gradient(
    0deg,
    rgba(27, 176, 168, 0.1) 33%,
    rgba(27, 176, 168, 1) 100%
  );
  animation: spin 0.8s linear 0s infinite;
}

.loading-spinner__inner {
  width: 100%;
  height: 100%;
  background-color: var(--color-text-white);
  border-radius: 50%;
}

.loading-spinner__text {
  font-size: var(--size-m);
  font-weight: 500;
  letter-spacing: 0.74px;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
