import { inject } from 'vue';
import { invokeKey, fetchPageBuilder } from '@drapejs/core';
import * as bellaliteAddToCart from '@/connectors/add-to-cart';

import useContext from '@/composables/useContext';

export default function () {
  const { route } = useContext();
  const invoke = inject(invokeKey);

  async function getBaseProduct(baseProductId: string, variantId?: string) {
    const request: any = fetchPageBuilder(
      route.protocol,
      route.host,
      route.pathname,
      {
        ...route.query,
      },
      ''
    );

    request.baseProductId = baseProductId;
    request.variantId = variantId;
    request.url = `${request.protocol}//${request.host}${request.path}`;

    const baseProduct = await invoke?.(
      bellaliteAddToCart.commands.fetchProduct,
      request
    );

    return baseProduct;
  }

  async function getVariant(variantId: string) {
    const request: any = fetchPageBuilder(
      route.protocol,
      route.host,
      route.pathname,
      {
        ...route.query,
      },
      ''
    );

    request.variantId = variantId;
    request.url = `${request.protocol}//${request.host}${request.path}`;

    const variant = await invoke?.(
      bellaliteAddToCart.commands.fetchVariant,
      request
    );

    return variant;
  }

  return {
    getBaseProduct,
    getVariant,
  };
}
