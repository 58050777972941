function createObserverOrReturnNull() {
    if (typeof window === 'undefined') return null;

    return new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.dispatchEvent(new CustomEvent('intersection'));
                }
            });
        },
        {
            rootMargin: '0px',
            threshold: 0.8,
        }
    );
}

const intersectionObserver = createObserverOrReturnNull();

export const IntersectionObserverDirective = {
    mounted(el: any) {
        intersectionObserver?.observe(el);
    },
    beforeUnmount(el: any) {
        intersectionObserver?.unobserve(el);
    },
};
