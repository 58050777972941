import { defineReceiver, defineCommand } from '@drapejs/invoker';
import { gql } from 'graphql-request';
import { request, query } from '@distancify/drapejs-litium';
import { Invoker } from '@drapejs/invoker';

export const commands = {
  getCustomerPrices: defineCommand<{
    url: string;
    variantIds: string[];
  }>('getCustomerPrices'),
};

export const receivers = {
  getCustomerPrices: defineReceiver(
    commands.getCustomerPrices,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          query getCustomerPrices($url: String!, $variantIds: [String]!) {
            session(url: $url) {
              customerPrices(variantIds: $variantIds) {
                priceBuckets
                error
              }
            }
          }
        `),
        {
          url: command.url,
          variantIds: command.variantIds,
        }
      );

      return result.session.customerPrices;
    }
  ),
};

export function configureCustomerPricesCommands(invoker: Invoker) {
  invoker
    .configureCommand(commands.getCustomerPrices)
    .addReceiver(receivers.getCustomerPrices);
}
