export const deviceWidths = {
  phoneMaxWidth: 767,
  tabletMaxWidth: 1023,
};

export const addressTypes = {
  billing: 'billing',
  shipping: 'shipping',
};

export const typeDefaults = {
  emptyGuidString: '00000000-0000-0000-0000-000000000000',
};

export const MY_PAGES = {
  AUTO_OPEN_KEY: 'MY_PAGES_OPEN_KEY',
};

export const WEBSITES = {
  BLACK: 'black',
  WHITE: 'white',
  COMPANY: 'company',
};

export const FILEDS_CHARACTERS_LIMIT = {
  careOf: 50,
  deliveryAddress: 50,
  firstName: 25,
  lastName: 25,
  orderMarking: 30,
} as const;
