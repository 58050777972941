/* eslint-disable no-return-assign */ /* eslint-disable array-callback-return */

<script>
import TextInput from './forms/TextInput.vue';
import useStock from '../composables/useStock';

export default {
  components: {
    TextInput,
  },
  props: {
    quantity: {
      type: Number,
    },
    packagingInfo: {
      type: String,
    },
    packQuantity: {
      type: Number,
      default: 1,
    },
    unitOfMeasurement: {
      type: String,
    },
    stockItems: {
      type: Array,
    },
    narrowStyle: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,    
    }
  },
  data: () => ({
    quantityModel: 0,
    errorModel: '',
    messageModel: '',
    changeQuantityTimeout: null,
  }),
  emits: ['update:quantity'],
  setup() {
    const { getWebsiteStringForStockItem } = useStock();

    return { getWebsiteStringForStockItem };
  },
  computed: {
    stockUnit() {
      return this.unitOfMeasurement || '';
    },
    allStockItems() {
      const stockItems = [...this.stockItems] || [];
      stockItems.sort((a, b) => a.amount - b.amount);
      stockItems.forEach((s) => (s.isActive = false));
      stockItems.some((s) => {
        if ((s.quantity || s.amount) >= this.quantityModel) {
          return (s.isActive = true);
        }
      });

      return stockItems;
    },
  },
  methods: {
    getStockLabelText(stockLabel) {
      if (stockLabel.websiteTextType === 'date') {
        return stockLabel.time;
      }

      return this.getWebsiteStringForStockItem(stockLabel, 'quantity_input');
    },
    setAmount(amount) {
      this.quantityModel = amount;
    },
    setError(error) {
      this.errorModel = error;
    },
    setMessage(message) {
      this.messageModel = message;
    }
  },
  watch: {
    error() {
      this.errorModel = this.error;
    },
    message() {
      this.messageModel = this.message;
    },
  },
  created() {
    this.quantityModel = this.quantity;
    this.errorModel = this.error;
    this.messageModel = this.message;

    this.$watch(
      () => this.quantityModel,
      (newValue, oldValue) => {
        this.errorModel = '';

        if (this.changeQuantityTimeout) {
          clearTimeout(this.changeQuantityTimeout);
        }
        this.changeQuantityTimeout = setTimeout(() => {
          this.$emit('update:quantity', parseInt(newValue));
        }, 500);
      }
    );
  },
};
</script>

<template>
  <div
    class="quantity-input"
    :class="{ 'quantity-input--narrow': narrowStyle }"
  >
    <div class="quantity-input__quantity-wrapper">
      <text-input
        type="number"
        class="quantity-input__input"
        v-model="quantityModel"
        :min="1"
        :autoselect="true"
        :error-message="errorModel"
        :show-error-message="false"
        :disabled="disabled"
      />
      <div class="quantity-input__package-info" v-if="packagingInfo">
        {{ packagingInfo }}
      </div>
    </div>
    <div class="quantity-input__message">{{ messageModel }}</div>
    <div class="quantity-input__stock-wrapper">
      <div
        class="quantity-input__stock-item"
        :class="{ 'quantity-input__stock-item--active': stockItem.isActive }"
        v-for="stockItem in allStockItems"
        :key="stockItem.systemId"
      >
        <img
          class="quantity-input__stock-item-checkmark"
          src="../static/icons/stock_checkmark_icon.svg"
          v-if="stockItem.isActive"
        />
        <div>
          {{ getStockLabelText(stockItem.stockLabel) }}:
          {{
            stockItem.deliveryDate
            ? getWebsiteStringForStockItem(stockItem.deliveryDate, 'quantity_input')
            : `${stockItem.amount} ${stockUnit}`
          }}
        </div>
      </div>
    </div>
  </div>
  <div v-if="errorModel" class="quantity-input_error">
    {{ errorModel }}
  </div>
</template>

<style>
.quantity-input {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.quantity-input__quantity-wrapper {
  display: flex;
  width: 100%;
}

.quantity-input__input {
  width: 90px;
}

/* Chrome, Safari, Edge, Opera */
.quantity-input__input input::-webkit-outer-spin-button,
.quantity-input__input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.quantity-input__input input[type='number'] {
  -moz-appearance: textfield;
  text-align: left;
}

.quantity-input__package-info {
  width: 65%;
  font-size: var(--size-xxs);
  color: var(--color-feedback-info);
  line-height: 15px;
  padding-left: 0.75rem;
}

.quantity-input__message {
  color: var(--color-main-90);
  line-height: 22px;
  font-size: var(--size-xs);
}

.quantity-input__stock-wrapper {
  margin-top: 0.75rem;
}

.quantity-input__stock-item {
  padding: 0.25rem 0.75rem;
  color: var(--color-main-90);
  line-height: 22px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  font-size: var(--size-xs);
  cursor: default;
}

.quantity-input__stock-item--active {
  background-color: var(--color-main-100);
  color: var(--color-text-white);
}

.quantity-input__stock-item-checkmark {
  padding-right: 0.35rem;
}

.quantity-input_error {
  text-align: center;
  margin-bottom: 0.5rem;
  color: var(--color-feedback-warn);
  font-size: var(--size-xxs);
}
</style>
