/* eslint-disable no-param-reassign */
import {
  computed, inject, Ref, ref,
} from 'vue';
import { DataSourceEntries } from '@distancify/drapejs-storyblok';
import { textsKey } from '@/keys';

const tokenRegex = /\{{(.+?)\}}/gi;

function replace(textToFormat: string, websiteTexts: Ref<any>, tokens?: { [key: string]: string | number }) {
  if (!tokens) {
    tokens = {};
  }

  return textToFormat.replace(tokenRegex, (_, p1: string) => {
    if (tokens) {
      const token = tokens[p1.toLowerCase()];
      if (typeof token !== 'undefined') {
        return token.toString();
      }
    }

    const token = websiteTexts.value?.[p1.toLowerCase()];
    if (typeof token !== 'undefined') {
      return token.toString();
    }

    return `{${p1.toLowerCase()}}`;
  });
}

export default function (texts?: Ref<DataSourceEntries>) {
  if (!texts) {
    texts = inject(textsKey, ref({}));
  }

  return {
    websiteText: (key: string, tokens?: { [key: string]: string | number }) => computed<string>(() => replace(texts?.value?.[key] || key, texts || ref({}), tokens)),
  };
}
