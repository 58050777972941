<!-- eslint-disable no-underscore-dangle -->
<script>
import { getItemKey, setItemKey } from '@drapejs/core';
import {
  defineComponent,
  inject,
} from 'vue';
import LayoutRoot from './layouts/LayoutRoot.vue';

export default defineComponent({
  name: 'App',
  setup() {
    const getItem = inject(getItemKey);
    const setItem = inject(setItemKey);
    
    async function trySaveGa4Headers() {
      if (!window.__ga4_client_id) return;
      const litiumOptions = await getItem('__litium_options') || {};

      litiumOptions.additionalHeaders = {
        ...litiumOptions.additionalHeaders,
        'LH-GA4ClientId': window.__ga4_client_id,
        'LH-GA4SessionId': window.__ga4_session_id
      };

      await setItem('__litium_options', litiumOptions);
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('ga4_client_id_update', () => {
        trySaveGa4Headers();
      });
      
      window.addEventListener('ga4_session_id_update', e => {
        trySaveGa4Headers();
      });

      trySaveGa4Headers();
    }

    return {};
  },
  components: {
    LayoutRoot,
  },
});
</script>

<template>
  <router-view>
    <LayoutRoot />
  </router-view>
</template>
