import { inject, ref, Ref } from 'vue';
import { format } from 'date-fns';

import { pageKey, routeKey, invokeKey, navigateKey } from '@drapejs/core';
import { resolveLinkKey } from '@distancify/drapejs-storyblok';
import {
  settingsKey,
  textsKey,
  Settings,
  replaceTokensKey,
  emitterKey,
  channelKey,
  cartKey,
} from '@/keys';

import { dateLocales, buildUrlPathWithQuery } from '@/helpers/utils';
import { environmentKey, EnvironmentOptions } from '@/EnvironmentOptions';
import { MY_PAGES } from '@/constants';

export default function () {
  const texts = inject(textsKey, <any>{});
  const replaceTokens = inject(replaceTokensKey, () => '');

  const navigate = inject(navigateKey, ref<any>(<any>{}));
  const route = inject(routeKey, ref<any>(<any>{}));
  const cart = inject(cartKey, ref<any>(<any>{}));
  const channel = inject(channelKey, ref<any>(<any>{}));
  const settings: any = inject(settingsKey, ref<Settings>(<any>{}));

  const resolveLink = inject(resolveLinkKey, () => <any>null);

  function formatPrice(
    value: number,
    decimals?: number,
    locale?: string,
    currencyId?: string
  ) {
    if (value !== 0 && !value) {
      return '';
    }
    if (!cart?.value) {
      return value;
    }
    const maximumFractionDigits = decimals || 2;
    return new Intl.NumberFormat(locale || channel.value?.locale || 'sv-SE', {
      style: 'currency',
      currency: currencyId || cart.value.currency?.id || 'SEK',
      maximumFractionDigits,
      minimumFractionDigits: 0,
    }).format(value);
  }

  function formatDate(date: Date, dateFormat: string) {
    const language: Locale = (dateLocales as any)[
      cart.value?.locale || channel.value?.locale || 'sv-SE'
    ] as Locale;
    return format(date, dateFormat, { locale: language });
  }

  function navigateToLoginPage(query = null) {
    const search = query || route?.query || {};

    const completeQuery = {
      ...search,
      redirect: encodeURIComponent(route?.pathname || ''),
    };

    const loginSlug = resolveLink(() => settings.value?.login)?.url;

    if (loginSlug) {
      navigate(buildUrlPathWithQuery(`${loginSlug}`, completeQuery));
    } else if (channel.value?.rootPath) {
      const rootPageUrl = buildUrlPathWithQuery(
        channel.value.rootPath,
        completeQuery
      );
      navigate(rootPageUrl);
    }
  }

  function navigateToRegisterPage(query = null) {
    const search = query || route?.query || {};

    const completeQuery = {
      ...search,
      redirect: encodeURIComponent(route?.pathname || ''),
    };

    const registerSlug = resolveLink(() => settings.value?.register)?.url;

    if (registerSlug) {
      navigate(buildUrlPathWithQuery(`${registerSlug}`, completeQuery));
    } else if (channel.value?.rootPath) {
      const rootPageUrl = buildUrlPathWithQuery(
        channel.value.rootPath,
        completeQuery
      );
      navigate(rootPageUrl);
    }
  }

  function navigatePostLogin(redirectUrl?: string) {
    const search = route.query || {};
    const query = {
      ...search,
    };

    if (query.action) {
      if ('add' === query.action) {
        sessionStorage.setItem('add-to-cart', JSON.stringify(query));
      }

      if ('add-review' === query.action) {
        sessionStorage.setItem('add-review', JSON.stringify(query));
      }

      if ('my-pages' === query.action) {
        sessionStorage.setItem(MY_PAGES.AUTO_OPEN_KEY, true.toString());
      }

      delete query.action;
    }

    if (query.redirect) {
      delete query.redirect;
      const clientRedirect = buildUrlPathWithQuery(route.query.redirect, query);
      navigate(clientRedirect);
    } else if (redirectUrl) {
      const serverRedirect = buildUrlPathWithQuery(redirectUrl, query);
      navigate(serverRedirect);
    } else if (channel.value.rootPath) {
      navigate(channel.value.rootPath);
    }
  }

  return {
    page: inject(pageKey, ref()),
    settings,
    route,
    cart,
    replaceTokens: inject(replaceTokensKey, () => ''),
    texts: (key: string, args?: { [key: string]: string }) =>
      replaceTokens(texts.value?.[key] || key, args),
    mitt: inject(emitterKey, <any>{}),
    channel,
    formatPrice,
    formatDate,
    navigateToLoginPage,
    navigateToRegisterPage,
    navigatePostLogin,
    navigate,
    environmentOptions: inject(environmentKey, {} as EnvironmentOptions),
    resolveLink,
    invoke: inject(invokeKey, () => Promise.resolve()),
  };
}
