import { ref } from 'vue';

import useEventListener from '@/composables/useEventListener';

export default function () {
  const { useWindowEventListener } = useEventListener();

  const clientHeight = ref(0);

  function setClientHeight() {
    clientHeight.value = window.innerHeight;
  }

  useWindowEventListener('resize', setClientHeight, true);

  return {
    clientHeight,
  };
}
