import { ref, Ref, provide, onMounted, inject, readonly, watch } from 'vue';

import { User, userKey } from '../keys';
import useCache from './useCache';
import useContext from './useContext';

export default function () {
  const { subscribe, getItem } = useCache();
  const { route } = useContext();

  const pendingAuth = ref(true);
  provide('pendingAuth', pendingAuth);

  const user: Ref<User> = ref({ isAuthenticated: false });
  provide(userKey, readonly(user));

  function setPendingAuthState() {
    pendingAuth.value = true;
  }
  provide('setPendingAuthState', setPendingAuthState);

  function setUser(userValue: User, forcePending = false) {
    if (userValue) {
      pendingAuth.value = forcePending ? true : false;

      const isDirty = JSON.stringify(userValue) !== JSON.stringify(user.value);
      if (isDirty) {
        user.value = userValue;
      }
    }
  }
  provide('setUser', setUser);

  onMounted(async () => {
    handleUserSubscription(await getItem('__user'));

    subscribe('__user', (data) => {
      handleUserSubscription(data);
    });

    function handleUserSubscription(userValue: User) {
      if (userValue) {
        setUser(userValue);
      } else {
        setUser({ isAuthenticated: false }, true);
      }
    }

    watch(
      () => route.pathname,
      () => {
        setPendingAuthState();
      }
    );
  });
}

export function getUserProviderContext() {
  return {
    user: inject(userKey, ref<User>({ isAuthenticated: false })),
    pendingAuth: inject('pendingAuth', ref<boolean>(false)),
    setPendingAuthState: inject('setPendingAuthState', () => {}),
    setUser: inject('setUser', (userValue: User) => {}),
  };
}
