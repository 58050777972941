import { gql, GraphQLClient } from "graphql-request";
import { defineReceiver, defineCommand, Invoker } from "@drapejs/invoker";
import Cache from "@drapejs/cache";
import { query } from "@distancify/drapejs-litium";

let client: GraphQLClient;

export const commands = {
  addReview: defineCommand<{
    transactionId: string;
    variantId: string;
    authorId: string;
    transactionSecret: string;
    review: string;
  }>("addReview"),
  reviewsByAuthor: defineCommand<{
    authorId: string;
    take: number;
  }>("reviewsByAuthor"),
  reviewsByProduct: defineCommand<{
    baseProductId: string;
    take: number;
  }>("reviewsByProduct"),
};

export const receivers = {
  addReview: defineReceiver(
    commands.addReview,
    async (command, data) => {
      const result = await client.request(
        query(gql`
          mutation createReview(
            $itemId: String!
            $transactionId: String
            $authorId: String
            $transactionSecret: String
            $productRating: RatingInputType
          ) {
            createReview(
              itemId: $itemId
              transactionId: $transactionId
              authorId: $authorId
              transactionSecret: $transactionSecret
              productRating: $productRating
            ) {
              id
              secret
            }
          }
        `).query,
        {
          itemId: command.variantId,
          transactionId: command.transactionId,
          transactionSecret: command.transactionSecret,
          authorId: command.authorId,
          productRating: {
            value: 1,
            comment: command.review || "",
          },
        }
      );

      return result.createReview;
    },
    "tycka"
  ),
  reviewsByAuthor: defineReceiver(
    commands.reviewsByAuthor,
    async (command, data) => {
      const result = await client.request(
        query(gql`
          query reviews($filter: Filter, $take: Int, $sort: SortInput) {
            reviews(filter: $filter, take: $take, sort: $sort) {
              total
              hits {
                id
                item {
                  id
                  imageId
                  name
                  brandName
                  productUrls
                }
                images
                productRating {
                  comment
                }
              }
            }
          }
        `).query,
        {
          filter: {
            author_id: command.authorId,
          },
          sort: { order: ["createdAt_desc"] },
          take: command.take,
        }
      );

      return result.reviews;
    },
    "tycka"
  ),
  reviewsByProduct: defineReceiver(
    commands.reviewsByProduct,
    async (command, data) => {
      const result = await client.request(
        query(gql`
          query reviews($filter: Filter, $take: Int, $sort: SortInput) {
            reviews(filter: $filter, take: $take, sort: $sort) {
              total
              hits {
                author {
                  name
                  title
                  profileImageId
                  reviewerId
                }
                id
                item {
                  id
                  name
                }
                productRating {
                  value
                  comment
                }
              }
            }
          }
        `).query,
        {
          filter: {
            item_baseProduct: command.baseProductId,
          },
          sort: { order: ["createdAt_desc"] },
          take: command.take,
        }
      );

      return result.reviews;
    },
    "tycka"
  ),
};

export function configureTyckaCommands(invoker: Invoker) {
  invoker.configureCommand(commands.addReview).addReceiver(receivers.addReview);

  invoker
    .configureCommand(commands.reviewsByAuthor)
    .addReceiver(receivers.reviewsByAuthor);

  invoker
    .configureCommand(commands.reviewsByProduct)
    .addReceiver(receivers.reviewsByProduct);
}

export function init(cache: Cache, endpoint: string) {
  client = new GraphQLClient(endpoint, {
    fetch: async (url: string, options: any) => {
      if (!options.headers) {
        options.headers = {};
      }
      const promise = fetch(url, options);
      promise
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error(err);
        });

      return promise;
    },
  });
}
