import { inject, ref } from 'vue';
import { format } from 'date-fns';
import { cartKey } from '@/keys';
import { dateLocales } from '../helpers/utils';
import useContext from '@/composables/useContext';

export default function () {
    const cart = inject(cartKey, ref({}));
    const { channel } = useContext();

    return {
        formatDate(date: Date, dateFormat: string) {
            const locale = cart.value?.locale || channel.value?.locale || 'sv-SE';
            const language: Locale = (dateLocales as any)[locale] as Locale;
            return format(date, dateFormat, { locale: language });
        },
    };
}
