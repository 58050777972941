<script>
import { fetchPageBuilder } from '@drapejs/core';

import { validateEmail } from '../../helpers/utils.ts';

import MyPagesBack from './components/MyPagesBack.vue';
import ErrorMessage from '../forms/ErrorMessage.vue';

import PersonGeneralInfo from '@/components/MyPages/MyInformation/PersonGeneralInfo.vue';
import Checkbox from "../forms/Checkbox.vue";

import * as user from '@/connectors/user';
import { ERRORS } from '@/composables/constants';

export default {
  components: {
    MyPagesBack,
    PersonGeneralInfo,
    ErrorMessage,
    Checkbox,
  },
  emits: ['close-modal', 'back'],
  data() {
    return {
      newEmail: '',
      newEmailError: '',
      validationSent: false,
      savePersonalInfoError: '',
      saveNewEmailError: '',
      currentPassword: '',
      currentPasswordError: '',
      newPassword: '',
      newPasswordError: '',
      confirmPassword: '',
      confirmPasswordError: '',
      savePasswordError: '',
      requestPending: false,
      passwordChangedMessage: '',
      audienceChangeError: '',
    };
  },
  computed: {
    currentEmailText() {
      return this.$replaceTokens(
        this.$globalTexts.mypages__current_email ||
          'mypages__current_email {email} {{email}}',
        {
          email: this.$user?.person?.email,
        }
      );
    },
    hasPendingEmail() {
      return this.$user?.person?.emailUnverified;
    },
    pendingEmailText() {
      return this.$replaceTokens(
        this.$globalTexts.mypages__pending_email ||
          'mypages__pending_email {email} {{email}}',
        {
          email: this.$user.person.emailUnverified,
        }
      );
    },
    passwordRegex() {
      return this.$globalFields?.PasswordPolicyRegex || '';
    },
    audiences() {
      return this.$user.audiences || [];
    },
    hasAudiences() {
      return this.audiences.length !== 0;
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    onBack() {
      this.$emit('back');
    },
    clearError(error) {
      this.passwordChangedMessage = '';
      this[error] = '';
    },
    async saveNewEmail() {
      if (!validateEmail(this.newEmail)) {
        this.newEmailError =
          this.$globalTexts.global__email_invalid || 'global__email_invalid';
        return;
      }

      await this.sendEmailValidation(this.newEmail);
    },
    async resendEmailValidation() {
      await this.sendEmailValidation(this.$user?.person?.emailUnverified);
    },
    async sendEmailValidation(email) {
      const requestArgs = this.buildCommandArgs({
        newEmail: email,
      });

      const response = await this.executeCommand(
        user.commands.sendEmailVerification,
        requestArgs
      );

      switch (response.error) {
        case ERRORS.NOT_AUTHENTICATED:
          this.$navigateToLoginPage();
          break;        
        case 'FAILED':
          this.saveNewEmailError =
            this.$globalTexts.global__save_error || 'global__save_error';
          break;

        default:
          this.validationSent = true;

          var that = this;
          setTimeout(() => {
            that.validationSent = false;
          }, 2000);
      }
    },
    async saveNewPassword() {
      if (!this.currentPassword) {
        this.currentPasswordError =
          this.$globalTexts.global__required_field || 'global__required_field';
        return;
      }

      if (!this.newPassword) {
        this.newPasswordError =
          this.$globalTexts.global__required_field || 'global__required_field';
        return;
      }

      if (!this.confirmPassword) {
        this.confirmPasswordError =
          this.$globalTexts.global__required_field || 'global__required_field';
        return;
      }

      if (
        this.passwordRegex &&
        !RegExp(this.passwordRegex).test(this.newPassword)
      ) {
        this.newPasswordError =
          this.$globalTexts.mypages__new_password_complexity ||
          'mypages__new_password_complexity';
        return;
      }

      if (this.newPassword !== this.confirmPassword) {
        this.confirmPasswordError =
          this.$globalTexts.mypages__repeat_password_missmatch ||
          'mypages__repeat_password_missmatch';
        return;
      }

      const requestArgs = this.buildCommandArgs({
        oldPassword: this.currentPassword,
        newPassword: this.newPassword,
      });

      const response = await this.executeCommand(
        user.commands.changePassword,
        requestArgs
      );

      switch (response.error) {
        case 'INVALID_PASSWORD':
          this.currentPasswordError =
            this.$globalTexts.mypages__current_password_invalid ||
            'mypages__current_password_invalid';
          break;
        case ERRORS.NOT_AUTHENTICATED: {
          this.$navigateToLoginPage();
          break;
        }  
        case 'NONE':
          this.currentPassword = '';
          this.newPassword = '';
          this.confirmPassword = '';
          this.passwordChangedMessage =
            this.$globalTexts.mypages__password_changed ||
            'mypages__password_changed';
          break;
        case 'FAILED':
        default:
          this.savePasswordError =
            this.$globalTexts.mypages__profile || 'mypages__profile';
          break;
      }
    },
    async audienceUpdated(audience) {
      const requestArgs = this.buildCommandArgs({
        email: this.$user.person.email,
        audience: audience.id,
      });

      const response = audience.isSubscribed 
                      ? await this.executeCommand(user.commands.subscribeToAudience, requestArgs)
                      : await this.executeCommand(user.commands.unsubscribeFromAudience, requestArgs);

      switch (response.error) {
        case 'FAILED':
          this.audienceChangeError = this.$globalTexts.global__generic_error || 'global__generic_error';
          break;

        default:
          this.audienceChangeError = '';
      }
    },
    buildCommandArgs(formArgs) {
      return {
        ...formArgs,
        ...fetchPageBuilder(
          this.$route.protocol,
          this.$route.host,
          this.$route.pathname,
          { ...this.$route.query },
          ''
        ),
      };
    },
    async executeCommand(command, args) {
      try {
        this.requestPending = true;
        const response = await this.$invoke(command, args);
        return response;
      } catch (err) {
        return [];
      } finally {
        this.requestPending = false;
      }
    },
  },
};
</script>

<template>
  <div class="person-profile">
    <my-pages-back @back="onBack()" @exit="closeModal()" />

    <div class="person-profile__content">
      <div class="person-profile__title">
        {{ $globalTexts.mypages__profile || 'mypages__profile' }}
      </div>

      <PersonGeneralInfo />

      <div class="person-profile__section">
        <div class="person-profile__subtitle">
          {{ $globalTexts.global__email || 'global__email' }}
        </div>

        <div class="person-profile__text" v-html="currentEmailText" />

        <div class="person-profile__field-group">
          <label class="person-profile__label" for="newEmail">{{
            $globalTexts.mypages__new_email || 'mypages__new_email'
          }}</label>
          <input
            type="text"
            id="newEmail"
            class="person-profile__input"
            autocomplete="username"
            v-model="newEmail"
          />
          <error-message v-if="newEmailError" :message="newEmailError" />
          <div v-if="hasPendingEmail">
            <span
              v-html="pendingEmailText"
              class="person-profile__label"
            ></span>
            <a
              href=""
              @click.prevent="resendEmailValidation"
              class="person-profile__label"
              >({{
                $globalTexts.mypages__resend_validation ||
                'mypages__resend_validation'
              }})</a
            >
          </div>
        </div>

        <button
          class="secondary-button secondary-button--inverted"
          @click="saveNewEmail"
          :disabled="requestPending"
        >
          {{ $globalTexts.global__validate_email || 'global__validate_email' }}
        </button>
        <error-message v-if="saveNewEmailError" :message="saveNewEmailError" />
        <span class="person-profile__message" v-if="validationSent">{{
          $globalTexts.mypages__new_email_validation ||
          'mypages__new_email_validation'
        }}</span>
      </div>

      <div class="person-profile__section">
        <div class="person-profile__subtitle">
          {{ $globalTexts.global__password || 'global__password' }}
        </div>

        <div class="person-profile__field-group">
          <label class="person-profile__label" for="currentPassword">{{
            $globalTexts.mypages__current_password ||
            'mypages__current_password'
          }}</label>
          <input
            type="password"
            id="currentPassword"
            autocomplete="current-password"
            class="person-profile__input"
            v-model="currentPassword"
            @input="clearError('currentPasswordError')"
          />
          <error-message
            v-if="currentPasswordError"
            :message="currentPasswordError"
          />
        </div>

        <div class="person-profile__field-group">
          <label class="person-profile__label" for="newPassword">{{
            $globalTexts.mypages__new_password || 'mypages__new_password'
          }}</label>
          <input
            type="password"
            id="newPassword"
            autocomplete="new-password"
            class="person-profile__input"
            v-model="newPassword"
            @input="clearError('newPasswordError')"
          />
          <error-message v-if="newPasswordError" :message="newPasswordError" />
        </div>

        <div class="person-profile__field-group">
          <label class="person-profile__label" for="repeatPassword">{{
            $globalTexts.mypages__repeat_password
          }}</label>
          <input
            type="password"
            id="repeatPassword"
            autocomplete="new-password"
            class="person-profile__input"
            v-model="confirmPassword"
            @input="clearError('confirmPasswordError')"
          />
          <error-message
            v-if="confirmPasswordError"
            :message="confirmPasswordError"
          />
        </div>

        <div
          v-if="passwordChangedMessage"
          class="person-profile__field-group person-profile__form-submitted"
        >
          {{ passwordChangedMessage }}
        </div>

        <button
          class="secondary-button secondary-button--inverted"
          @click="saveNewPassword"
          :disabled="requestPending"
        >
          {{ $globalTexts.mypages__save_password || 'mypages__save_password' }}
        </button>
        <error-message v-if="savePasswordError" :message="savePasswordError" />

        <div 
          class="person-profile__audiences"
          v-if="hasAudiences"
        >
          <div 
            class="person-profile__audiences-title"
          >
            {{ $globalTexts.mypages__audiences || 'mypages__audiences' }}
          </div>
          <div 
            class="person-profile__audiences-checkbox"
            v-for="audience in audiences"
            :key="audience.id">
              <checkbox
                v-model="audience.isSubscribed"
                :text="audience.name"
                @update:modelValue="audienceUpdated(audience)"
              />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.person-profile {
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-text-white);
}

.person-profile__content {
  padding: 0px 10px 10px;
  overflow-y: auto;
}

.person-profile__section {
  padding-top: 15px;
}

.person-profile__title {
  font-weight: 700;
  font-size: var(--size-xxl);
  line-height: 32px;
  color: var(--color-black);
  padding-top: 10px;
}

.person-profile__subtitle {
  font-weight: 400;
  font-size: var(--size-xl);
  line-height: 35px;
  color: var(--color-main-90);
  margin: 14px 0;
}

.person-profile__field-group {
  margin-bottom: 14px;
}

.person-profile__label {
  font-weight: 400;
  font-size: var(--size-xxs);
  line-height: 14px;
  color: var(--color-main-90);
}

.person-profile__input {
  color: var(--color-dark-gray);
}

.person-profile__text {
  font-weight: 400;
  font-size: var(--size-xs);
  line-height: 19px;
  margin-bottom: 14px;
}

.person-profile__message {
  font-size: var(--size-xxs);
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.18px;
  margin-top: 0.25rem;
  display: block;
}

.person-profile__form-submitted {
  color: var(--color-feedback-info);
  font-size: var(--size-xss);
  line-height: 14px;
}

.person-profile__audiences {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.person-profile__audiences-title {
  font-weight: 700;
  font-size: var(--size-l);
  line-height: 26px;
}

.person-profile__audiences-checkbox {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .person-profile__content {
    padding: 0px 30px 20px;
  }
}
</style>
@/composables/constants.js