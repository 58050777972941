
import mitt from 'mitt';

import {
  onServerPrefetch,
  ref,
  inject,
  provide,
  onMounted,
  watchEffect,
} from 'vue';

import {
  fetchPage,
  fetchPageBuilder,
  getServerItemKey,
  routeKey,
  subscribeKey,
  invokeKey,
  getItemKey,
} from '@drapejs/core';

import { useDataSources, configureResolveLinkRewriter } from '@distancify/drapejs-storyblok';

import {
  settingsKey,
  cartKey,
  userKey,
  textsKey,
  channelKey,
  metaProductKey,
  categoryTreeKey,
  replaceTokensKey,
  currentPageKey,
  emitterKey,
} from '../keys';

import useModals from '@/composables/useModals';
import useContext from '@/composables/useContext';
import useStoryblokLink from '@/composables/useStoryblokLink';
import useUserProvider from '@/composables/useUserProvider';

import StandardLayout from './StandardLayout.vue';
import {
  usePageView,
} from '@distancify-storefront/tracking-gtm';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

const tokenRegex = /\{{(.+?)\}}/gi;

export default {
  components: {
    StandardLayout,
  },
  setup() {
    const getServerItem = inject(getServerItemKey, () => null);
    const getItem = inject(getItemKey, async () => null);
    const subscribe = inject(subscribeKey, async () => {});
    const invoke = inject(invokeKey, async () => null);
    const route = inject(routeKey, <any>{});
    const settingsFetchPage = fetchPageBuilder(
      route.protocol,
      route.host,
      `/settings`
    );
    const {
      fetchDataSource,
      getDataSource,
      getServerDataSource,
      subscribeDataSource,
    } = useDataSources();
    const { environmentOptions } = useContext();
    const { toUrl } = useStoryblokLink();

    const textSiteId = environmentOptions.siteId === 'company' ? 'company' : 'black';
    const textsId = `texts_${textSiteId}`;

    const cart = ref();
    provide(cartKey, cart);

    const currentPage = ref();
    provide(currentPageKey, currentPage);

    const settings = ref(getServerItem(settingsFetchPage.cacheKey));
    provide(settingsKey, settings);

    const texts = ref(getServerDataSource(textsId));
    provide(textsKey, texts);

    const channel = ref(getServerItem('__channel'));
    provide(channelKey, channel);

    const categoryTree = ref(getServerItem('__categoryTree'));
    provide(categoryTreeKey, categoryTree);

    const metaProduct = ref();
    provide(metaProductKey, metaProduct);

    const emitter = mitt();
    provide(emitterKey, emitter);

    provide(
      replaceTokensKey,
      (text: string, args?: { [key: string]: string }) => {
        const replace = (textToFormat: string) => {
          return textToFormat.replace(
            tokenRegex,
            (_, p1) =>
              args?.[p1.toLowerCase()] || texts.value?.[p1.toLowerCase()] || ''
          );
        };

        return !text ? text : replace(text);
      }
    );

    watchEffect(() => {
      metaProduct.value = channel.value?.metaProduct?.fieldsJson;
    });

    onServerPrefetch(async () => {
      settings.value = await invoke(fetchPage, settingsFetchPage);
      texts.value = await fetchDataSource(textsId);
    });

    configureResolveLinkRewriter((link) => {
      if (link.isExternal) {
        return link;
      }

      let slug = link.url || '';
      if (environmentOptions.siteId && slug.includes(environmentOptions.siteId)){
        slug = slug.replace(environmentOptions.siteId, '');
      }
      
      if(channel.value?.rootPath && slug){
        slug = '/' + channel.value.rootPath + '/' + slug;
      }
      
      slug = slug.replace(/\/+/g, '/');

      link.url = slug;

      return link;
    });

    subscribe(settingsFetchPage.cacheKey, (val) => {
      settings.value = val;
    });

    if (!settings.value) {
      onMounted(async () => {
        settings.value = await getItem(settingsFetchPage.cacheKey);
        settings.value = await invoke(fetchPage, settingsFetchPage);
      });
    }

    subscribe('__channel', (val) => {
      channel.value = val;
    });

    subscribe('__categoryTree', (val) => {
      categoryTree.value = val;
    });

    onMounted(async () => {
      (window as any).reopenConsent = () => {
        if (typeof (window as any).Cookiebot !== 'undefined') {
            (window as any).Cookiebot.show();
        }
      };

      subscribe('__cart', (data) => {
        cart.value = data;
      });

      cart.value = await getItem('__cart');

      if (!channel.value) {
        channel.value = await getItem('__channel');
      }

      if (!categoryTree.value) {
        categoryTree.value = await getItem('__categoryTree');
      }
    });

    subscribeDataSource(textsId, (val) => {
      texts.value = val;
    });

    if (!texts.value) {
      onMounted(async () => {
        texts.value = await getDataSource(textsId);
        texts.value = await fetchDataSource(textsId);
      });
    }

    useUserProvider();
    usePageView(async () => ({ channel: channel.value?.systemId }));
    useModals();

    return {
      websiteTexts: useWebsiteTexts(texts),
      toUrl,
      settings,
    };
  },
};
