import { InjectionKey } from 'vue';

export interface EnvironmentOptions {
  storyblokToken: string
  litiumBaseUrl: string
  tyckaAccount: string
  siteId: string
  maxStoryblokFolderDepth: string | null
}

export const environmentKey: InjectionKey<EnvironmentOptions> = Symbol('environment');